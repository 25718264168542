import axios from "axios";

const http = axios.create({ 
  baseURL: "https://euro-solucoes-esportivas-1b5630df6a01.herokuapp.com",
  headers: {
    "Content-type": "application/json",
  },
});

export { http };
