/* eslint-disable no-unused-vars */
import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";

import { useState } from "react";

import arrebite from "../assets/images/arrebite.jpeg";
import olympique from "../assets/images/olympique.jpeg";
import { LogosTimes } from "./logoTime";

export const Match = ({
  timeMandante,
  timeVisitante,
  timeMandanteLogin,
  timeVisitanteLogin,
}) => {
  const [primeiroValor, setPrimeiroValor] = useState(0);
  const [segundoValor, setSegundoValor] = useState(0);

  const aumentarPrimeiroValor = () => {
    setPrimeiroValor(primeiroValor + 1);
  };

  const aumentarSegundoValor = () => {
    setSegundoValor(segundoValor + 1);
  };

  function limparLocalStorage() {
    localStorage.clear();
  }

  return (
    <Flex
      gap="2rem"
      padding="1rem"
      boxShadow="xl"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDir="column" alignItems="center">
        <LogosTimes imageName={timeMandanteLogin} />
        <Text fontWeight="bold">{timeMandante}</Text>
      </Box>
      <Box>
        <Flex alignItems="center" gap="1rem" paddingTop="50%">
          <Text fontSize="4xl" fontWeight="bold">
            {primeiroValor}
          </Text>

          <Text fontSize="4xl" fontWeight="bold">
            X
          </Text>

          <Text fontSize="4xl" fontWeight="bold">
            {segundoValor}
          </Text>
        </Flex>
        <Button colorScheme="teal" variant="link" marginTop="1rem" onClick={limparLocalStorage}>
          <Link href={`/tabela/${timeMandanteLogin}/${timeVisitanteLogin}`}>Súmula</Link>
        </Button>
        <Text></Text>
        <Button colorScheme="teal" variant="link" marginTop="0.5rem" onClick={limparLocalStorage}>
          <Link href={`/tabelasemdados/${timeMandanteLogin}/${timeVisitanteLogin}`}>png</Link>
        </Button>
      </Box>

      <Box display="flex" flexDir="column" alignItems="center">
        <LogosTimes imageName={timeVisitanteLogin} />
        <Text fontWeight="bold">{timeVisitante}</Text>
      </Box>
    </Flex>
  );
};
