/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Text,
  Flex,
  Textarea,
  Button,
  useMediaQuery,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import axios from "axios";

import { LogosTimes } from "./logoTime";

import { useParams } from "react-router-dom";

import { exportAsImage } from "../utils/exportAsImage";
import { http } from "../services/http";

export const JogadoresTable = () => {
  const [isLargerThan1396] = useMediaQuery("(min-width: 1396px)");
  const [isLargerThan1176] = useMediaQuery("(min-width: 1176px)");
  const [isLargerThan956] = useMediaQuery("(min-width: 956px)");
  const [isLargerThan756] = useMediaQuery("(min-width: 756px)");

  const exportRef = useRef();

  const [timeCasa, setTimeCasa] = useState();
  const [timeVisitante, setTimeVisitante] = useState();
  const [jogadorCasa, setJogadorCasa] = useState([]);
  const [jogadorVisitante, setJogadorVisitante] = useState([]);

  const [texto, setTexto] = useState("");
  const [notes, setNotes] = useState([""]);

  const { mandanteId, visitanteId } = useParams();

  const getJogadores = async (time) => {
    const URL = `/euro/api/equipes/${time}/0`;  
    return await http.get(URL); 
  };

  const getTimes = async () => {
    const URL = `/euro/api/times/listarTodos`;  
    try {
      const { data } = await http.get(URL); 
      console.log(data);

      const findTimeCasa = data.filter(
        (time) => time.timesId.login === mandanteId
      )[0];
      const findTimeVisitante = data.filter(
        (time) => time.timesId.login === visitanteId
      )[0];

      setTimeCasa(findTimeCasa);
      setTimeVisitante(findTimeVisitante);

      localStorage.setItem("timeCasa", JSON.stringify(findTimeCasa));
      localStorage.setItem("timeVisitante", JSON.stringify(findTimeVisitante));

      const jogadoresCasa = await getJogadores(mandanteId);
      const jogadoresVisitante = await getJogadores(visitanteId);

      localStorage.setItem("jogadoresCasa", JSON.stringify(jogadoresCasa.data));
      localStorage.setItem(
        "jogadoresVisitante",
        JSON.stringify(jogadoresVisitante.data)
      );

      setJogadorCasa(jogadoresCasa.data);
      setJogadorVisitante(jogadoresVisitante.data);
    } catch (error) {
      console.log("Erro: ", error);
    }
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function limparLocalStorage() {
    localStorage.clear();
  }

  const atualizaDados = debounce(() => {
    console.log(jogadorCasa);
    localStorage.setItem("jogadoresCasa", JSON.stringify(jogadorCasa));
    localStorage.setItem(
      "jogadoresVisitante",
      JSON.stringify(jogadorVisitante)
    );
  });

  useEffect(() => {
    const localStorageJogadoresCasa = JSON.parse(
      localStorage.getItem("jogadoresCasa")
    );
    const localStorageJogadoresVisitante = JSON.parse(
      localStorage.getItem("jogadoresVisitante")
    );
    if (!(localStorageJogadoresCasa && localStorageJogadoresVisitante)) {
      getTimes();
    } else {
      const localStorageTimeCasa = JSON.parse(localStorage.getItem("timeCasa"));
      const localStorageTimeVisitante = JSON.parse(
        localStorage.getItem("timeVisitante")
      );
      setJogadorCasa(localStorageJogadoresCasa);
      setJogadorVisitante(localStorageJogadoresVisitante);
      setTimeCasa(localStorageTimeCasa);
      setTimeVisitante(localStorageTimeVisitante);
    }

    limparLocalStorage();
  }, []);

  return (
    <>
      <Button
        margin={isLargerThan1176 ? "2rem 2rem 1rem 1rem" : "2rem"}
        alignItems="center"
        justifyContent="center"
        colorScheme="green"
        bg="#38A169"
        color="#FFF"
        onClick={() => exportAsImage(exportRef.current, "sumula")}
      >
        Baixar súmula
      </Button>
      <Box ref={exportRef}>
        <Flex
          justifyContent="space-evenly"
          paddingY="1rem"
          fontSize="1.25rem"
          fontWeight="bold"
          alignItems={isLargerThan1176 ? "center%" : "flex-start"}
          flexDir={isLargerThan1176 ? "row" : "column"}
          w={isLargerThan1176 ? "100%" : "80%"}
          margin="auto"
          gap={1}
        >
          <p>Data: </p>
          <Input minW="20%" maxWidth={isLargerThan1176 ? "20%" : "100%"}></Input>
          <p>Juiz: </p>
          <Input minW="20%" maxWidth={isLargerThan1176 ? "20%" : "100%"}></Input>
          <p>Mesário: </p>
          <Input minW="20%" maxWidth={isLargerThan1176 ? "20%" : "100%"}></Input>
        </Flex>
        <Flex
          flexDir={isLargerThan1176 ? "row" : "column"}
          alignItems={isLargerThan1176 ? "" : "center"}
        >
          <Box
            w={isLargerThan1176 ? "50%" : "80%"}
            padding={isLargerThan1176 ? "1rem" : "1px"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    textAlign="center"
                    border="1px solid #000"
                    fontSize="1.25rem"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gap="1rem"
                    >
                      {timeCasa && <LogosTimes imageName={mandanteId} />}
                      <Text fontSize={isLargerThan1396 ? "1rem" : "1rem"}>
                        {timeCasa ? timeCasa.nomeTime : ""}
                      </Text>
                    </Flex>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    fontSize="1.25rem"
                    padding="0"
                  >
                    <Text fontSize={isLargerThan756 ? "1rem" : "0.75rem"}>
                      Placar:
                    </Text>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    padding="0"
                  >
                    <Input
                      maxW="4rem"
                      padding="0"
                      defaultValue={0}
                      type="number"
                      border="none"
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="1.5rem"
                    />
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    padding="0"
                  ></Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    borderRight="1px solid #000"
                    padding="0"
                  ></Th>
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  <Th
                    textTransform="capitalize"
                    textAlign="center"
                    border="1px solid #000"
                  >
                    Jogadores
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Gols
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Falta
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Am
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Vm
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {jogadorCasa.map((jogador, index) => (
                  <Tr key={jogador.id}>
                    <Td border="1px solid #000" padding="0.5rem">
                      <Flex alignItems="center" gap={3}>
                        <Input
                          type="number"
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          p="0px"
                          textAlign="center"
                          border="1px solid black"
                          max={999}
                        />
                        <Text textTransform="capitalize" textAlign="center">
                          {jogador.nomeJogador.toLowerCase()}
                        </Text>
                      </Flex>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.gols}
                          onChange={(e) => {
                            const newState = jogadorCasa.map((j) => {
                              if (jogadorCasa.indexOf(j) === index) {
                                return { ...j, gols: e.target.value };
                              }
                              return j;
                            });
                            setJogadorCasa(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.faltas}
                          onEnter
                          onChange={(e) => {
                            const newState = jogadorCasa.map((j) => {
                              if (jogadorCasa.indexOf(j) === index) {
                                return { ...j, faltas: e.target.value };
                              }
                              return j;
                            });
                            setJogadorCasa(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.amarelos}
                          onChange={(e) => {
                            const newState = jogadorCasa.map((j) => {
                              if (jogadorCasa.indexOf(j) === index) {
                                return { ...j, amarelos: e.target.value };
                              }
                              return j;
                            });
                            setJogadorCasa(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.vermelhos}
                          onChange={(e) => {
                            const newState = jogadorCasa.map((j) => {
                              if (jogadorCasa.indexOf(j) === index) {
                                return { ...j, vermelhos: e.target.value };
                              }
                              return j;
                            });
                            setJogadorCasa(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            w={isLargerThan1176 ? "50%" : "80%"}
            padding={isLargerThan1176 ? "1rem" : "1px"}
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    textAlign="center"
                    border="1px solid #000"
                    fontSize="1.25rem"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gap="1rem"
                    >
                      {timeVisitante && <LogosTimes imageName={visitanteId} />}
                      <Text fontSize={isLargerThan1396 ? "1rem" : "1rem"}>
                        {timeVisitante ? timeVisitante.nomeTime : ""}
                      </Text>
                    </Flex>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    fontSize="1.25rem"
                    padding="0"
                  >
                    <Text fontSize={isLargerThan756 ? "1rem" : "0.75rem"}>
                      Placar:
                    </Text>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    padding="0"
                  >
                    <Input
                      maxW="4rem"
                      padding="0"
                      defaultValue={0}
                      type="number"
                      border="none"
                      textAlign="center"
                      fontWeight="bold"
                      fontSize="1.5rem"
                    />
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    padding="0"
                  ></Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    borderRight="1px solid #000"
                    padding="0"
                  ></Th>
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  <Th
                    textTransform="capitalize"
                    textAlign="center"
                    border="1px solid #000"
                  >
                    Jogadores
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Gols
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Falta
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Am
                  </Th>
                  <Th
                    maxW="4rem"
                    minW="2rem"
                    padding="0"
                    textAlign="center"
                    border="1px solid #000"
                    textTransform="capitalize"
                  >
                    Vm
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {jogadorVisitante.map((jogador, index) => (
                  <Tr key={jogador.id}>
                    <Td border="1px solid #000" padding="0.5rem">
                      <Flex alignItems="center" gap={3}>
                        <Input
                          type="number"
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          p="0px"
                          textAlign="center"
                          border="1px solid black"
                          max={999}
                        />
                        <Text textTransform="capitalize" textAlign="center">
                          {jogador.nomeJogador.toLowerCase()}
                        </Text>
                      </Flex>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.gols}
                          onChange={(e) => {
                            const newState = jogadorVisitante.map((j) => {
                              if (jogadorVisitante.indexOf(j) === index) {
                                return { ...j, gols: e.target.value };
                              }
                              return j;
                            });
                            setJogadorVisitante(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.faltas}
                          onChange={(e) => {
                            const newState = jogadorVisitante.map((j) => {
                              if (jogadorVisitante.indexOf(j) === index) {
                                return { ...j, faltas: e.target.value };
                              }
                              return j;
                            });
                            setJogadorVisitante(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.amarelos}
                          onChange={(e) => {
                            const newState = jogadorVisitante.map((j) => {
                              if (jogadorVisitante.indexOf(j) === index) {
                                return { ...j, amarelos: e.target.value };
                              }
                              return j;
                            });
                            setJogadorVisitante(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                    <Td border="1px solid #000" padding="0">
                      <Box textAlign="center">
                        <Input
                          minWidth={isLargerThan756 ? "3rem" : "1.5rem"}
                          maxWidth={isLargerThan756 ? "3rem" : "2rem"}
                          padding="0"
                          defaultValue={0}
                          type="number"
                          border="none"
                          textAlign="center"
                          value={jogador.vermelhos}
                          onChange={(e) => {
                            const newState = jogadorVisitante.map((j) => {
                              if (jogadorVisitante.indexOf(j) === index) {
                                return { ...j, vermelhos: e.target.value };
                              }
                              return j;
                            });
                            setJogadorVisitante(newState);
                            atualizaDados();
                          }}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
        <Flex
          paddingTop="1rem"
          flexDir={isLargerThan1176 ? "row" : "column"}
          justifyContent={isLargerThan1176 ? "flex-start" : "center"}
        >
          <Flex
            flexDir={isLargerThan1176 ? "row" : "column"}
            w="80%"
            marginX="auto"
          >
            <Box w={isLargerThan756 ? "100%" : "80%"}>
              <Text fontWeight="bold" fontSize="20px">
                Observações:
              </Text>
              <Textarea
                placeholder="Digite as observações do jogo aqui!"
                padding="1rem"
                marginY="1rem"
                w="100%"
                h="10rem"
                border="1px solid #000"
                onChange={(e) => {
                  setTexto(e.target.value);
                }}
              ></Textarea>
              <Flex
                marginBottom={isLargerThan1176 ? "1rem" : "2rem"}
                justifyContent="flex-start"
              >
                <Button
                  colorScheme="green"
                  bg="#38A169"
                  color="#FFF"
                  onClick={() => {
                    setNotes([...notes, texto]);
                  }}
                >
                  Salvar anotação
                </Button>
              </Flex>
            </Box>
            <Box
              marginBottom={isLargerThan956 ? "1rem" : "2rem"}
              w="100%"
              minH="10rem"
              padding="1rem"
              marginBot
            >
              <Text fontWeight="bold" fontSize="20px" marginBottom="1rem">
                Anotações:
              </Text>
              {notes.map((note, i) => (
                <Text>{note}</Text>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
