import { Box, Input, Td } from '@chakra-ui/react';
import React from 'react';

function NoDataField() {
  return (
    <Td border="1px solid #000" padding="0">
      <Box textAlign="center">
        <Input
          maxW="4rem"
          padding="0"
          type="number"
          border="none"
          textAlign="center"
        />
      </Box>
    </Td>
  );
}

export default NoDataField;