import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useMediaQuery,
  Text,
  Flex,
  Select,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import AthletesListModal from "../Components/athletesListModal";
import AthleteDetailsModal from "../Components/athleteDetailsModal";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import InputMask from "react-input-mask";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import TeamAthletesModal from "../Components/teamAthletesModal";
import ListarAtletasModal from "../Components/listarAtletasModal";
import img2 from "../assets/images/img4.png";
import { http } from "../services/http";

const PlayerCreationPage = () => {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedChampionship, setSelectedChampionship] = useState("");
  const [selectedChampionshipId, setSelectedChampionshipId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [athleteData, setAthleteData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModa1lOpen, setIsModal1Open] = useState(false);
  const location = useLocation();
  const id = location.state?.id || "Valor padrão para id";
  const usuLogin = location.state?.usuLogin || "Valor padrão para usuLogin";
  const [atletaId, setAtletaId] = useState("");
  const [idCampeonato, setIdCampeonato] = useState(null);
  const [idTimes, setIdTimes] = useState(null);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [SelectedTeamId, setSelectedTeamId] = useState("");

  const handleOpenModal2 = (campeonatoId, timesId) => {
    setIdCampeonato(campeonatoId);
    setIdTimes(timesId);
    setIsModalOpen(true);
  };

  const formatCPF = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 6)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
    if (onlyNums.length <= 9)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
        6
      )}`;
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  };

  const handleCloseListModal = () => {
    setIsListModalOpen(false);
  };

  const formatDate = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 2) return onlyNums;
    if (onlyNums.length <= 4)
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
      4,
      8
    )}`;
  };

  const championshipValidationSchema = Yup.object().shape({
    championship: Yup.string().required("Por favor, selecione um campeonato"),
  });

  const athleteValidationSchema = Yup.object().shape({
    nomeCompleto: Yup.string().required("Nome do atleta é obrigatório"),
    cpf: Yup.string().required("CPF é obrigatório"),
    dataNascimento: Yup.string().required("Data de nascimento é obrigatória"),
    posicao: Yup.string().required("Posição do atleta é obrigatória"),
    imagem: Yup.mixed().nullable(), // Torna o campo de imagem opcional
  });

  const {
    register: registerChampionship,
    handleSubmit: handleSubmitChampionship,
    formState: { errors: errorsChampionship },
  } = useForm({
    resolver: yupResolver(championshipValidationSchema),
  });

  const {
    register: registerAthlete,
    handleSubmit: handleSubmitAthlete,
    formState: { errors: errorsAthlete },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(athleteValidationSchema),
    defaultValues: {
      time: "",
      posicao: "",
    },
  });

  const handleOpenListModal = () => {
    setIsListModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal1 = () => {
    setIsModal1Open(true);
  };

  const handleCloseModal1 = () => {
    setIsModal1Open(false);
  };

  useEffect(() => {
    const fetchChampionships = async () => {
      try {
        const response = await http.get("/euro/api/campeonatos/listarTodos");
        const championships = response.data.conteudo.map((championship) => ({
          id: championship.id,
          nome: championship.nomeCampeonato,
        }));
        setTeamOptions(championships);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchChampionships();
  }, []);

  const handleSelectChampionship = (event) => {
    const championshipId = event.target.value;
    setSelectedChampionshipId(championshipId);
    setSelectedChampionship(event.target.value);
    console.log(
      "ID:",
      id,
      "Usuário:",
      usuLogin,
      "Id Campeonato:",
      championshipId
    );
  };

  const cpfMask = "999.999.999-99";
  const dateMask = "99/99/9999";

  const handleImageChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleChampionshipSubmit = () => {
    setShowForm(true);
  };

  const handleAthleteSubmit = async (data) => {
    try {
      if (selectedFile && !(selectedFile instanceof Blob)) {
        console.error("Arquivo inválido.");
        toast({
          title: "Erro de envio",
          description: "Arquivo inválido.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      let imageBase64 = null;
      if (selectedFile) {
        imageBase64 = await toBase64(selectedFile);
      } else {
        imageBase64 = " ";  
      }
  
      const athleteData = {
        idTimes: id,
        nomeAtleta: data.nomeCompleto,
        documento: data.cpf,
        dataNasc: data.dataNascimento,
        telAtleta: "",
        posicaoAtleta: data.posicao,
        imagem: imageBase64,
      };
  
      const response = await http.post("/euro/api/atletas", athleteData);
   
      const createdAtletaId = response.data.conteudo.id;
  
      setAtletaId(createdAtletaId);
  
      const sumulaData = {
        sumulaId: {
          idCampeonato: selectedChampionshipId,
          idAtletas: createdAtletaId,
        },
        idJogo: null,
        idTimes: id,
        qtdGols: null,
        qtdAmarelo: null,
        qtdVermelho: null,
        qtdFaltas: null,
        aptoJogo: "não",
      };
  
      await http.post("/euro/api/sumula", sumulaData);
  
      toast({
        title: "Inclusão bem-sucedida.",
        description: `Atleta inserido com sucesso na equipe ${usuLogin}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      reset({
        nomeCompleto: "",
        cpf: "",
        dataNascimento: "",
        posicao: "",
        time: "",
      });
  
      setSelectedFile(null);
      setIsFilePicked(false);
    } catch (error) {
      console.error("There was an error!", error);
      const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "Atleta já cadastrado.";
      toast({
        title: "Erro ao inserir atleta",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  
  

  return (
    <Box
      bgImage={`url(${img2})`}
      bgSize="cover"
      bgPosition="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex={1}
    >
      {showForm ? (
        <Box
          w={isLargerThan480 ? "40%" : "90%"}
          p={4}
          mt={4}
          borderWidth={1}
          borderRadius="lg"
          boxShadow="lg"
          backgroundColor="white"
          position="relative"
        >
          <IconButton
            colorScheme="green"
            icon={<CloseIcon />}
            isRound="true"
            position="absolute"
            top="1rem"
            left="1rem"
            onClick={() => navigate(-1)}
          />

          <Text fontSize="xl" textAlign="center" paddingBottom={4} mb={4}>
            Atletas - {usuLogin}
          </Text>

          <form onSubmit={handleSubmitAthlete(handleAthleteSubmit)}>
            <FormControl
              id="nomeCompleto"
              isInvalid={errorsAthlete.nomeCompleto}
            >
              <FormLabel>Nome Completo</FormLabel>
              <Input
                size="sm"
                type="text"
                {...registerAthlete("nomeCompleto")}
                errorBorderColor="red.300"
              />
              <FormErrorMessage>
                {errorsAthlete.nomeCompleto?.message}
              </FormErrorMessage>
            </FormControl>

            <Flex mt={4} paddingTop={5}>
              <FormControl flex="1" id="cpf" isInvalid={errorsAthlete.cpf}>
                <FormLabel mt="-20px">CPF</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  {...registerAthlete("cpf")}
                  value={watch("cpf")}
                  onChange={(e) =>
                    setValue("cpf", formatCPF(e.target.value), {
                      shouldValidate: true,
                    })
                  }
                  errorBorderColor="red.300"
                />
                <FormErrorMessage>
                  {errorsAthlete.cpf?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                flex="1"
                id="dataNascimento"
                ml={4}
                isInvalid={errorsAthlete.dataNascimento}
              >
                <FormLabel mt="-20px">Data de Nasc</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  {...registerAthlete("dataNascimento")}
                  value={watch("dataNascimento")}
                  onChange={(e) =>
                    setValue("dataNascimento", formatDate(e.target.value), {
                      shouldValidate: true,
                    })
                  }
                  errorBorderColor="red.300"
                />
                <FormErrorMessage>
                  {errorsAthlete.dataNascimento?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <FormControl mt={4} id="posicao" isInvalid={errorsAthlete.posicao}>
              <FormLabel>Posição do Atleta</FormLabel>
              <Select
                size="sm"
                {...registerAthlete("posicao")}
                errorBorderColor="red.300"
              >
                <option value="">Selecione uma posição</option>
                <option value="Goleiro">Goleiro</option>
                <option value="Zagueiro">Zagueiro</option>
                <option value="Ala">Ala</option>
                <option value="Meia">Meia</option>
                <option value="Pivô">Pivô</option>
              </Select>
              <FormErrorMessage>
                {errorsAthlete.posicao?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} id="imagem">
              <FormLabel>Foto do Atleta</FormLabel>
              <Input type="file" size="sm" onChange={handleImageChange} />
            </FormControl>

            <VStack spacing={4} mt={4} align="stretch">


              
              <Button colorScheme="green" type="submit">
                Cadastrar
              </Button>
              {/*<Button
                colorScheme="green"
                onClick={() => {
                  alert("Inscrições Encerradas");
                }}
              >
                Cadastrar
              </Button> */}
<Menu>
  <MenuButton
    as={IconButton}
    aria-label="Options"
    icon={<HamburgerIcon />}
    variant="outline"
  />
  <MenuList>
    <MenuItem>
      <MenuItem onClick={handleOpenListModal} justifyContent="center">
        Listar Atletas
      </MenuItem>
    </MenuItem>

    <AthletesListModal
      isOpen={isListModalOpen}
      onClose={handleCloseListModal}
      SelectedTeam={SelectedTeamId}
    />
    <MenuItem
      onClick={() => handleOpenModal2(selectedChampionshipId, id)}
      justifyContent="center"
    >
      Atletas do Time
    </MenuItem>
  </MenuList>
</Menu>

              <ListarAtletasModal
                isOpen={isModa1lOpen}
                onClose={handleCloseModal1}
                championshipId={selectedChampionshipId}
              />
              <TeamAthletesModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                idCampeonato={idCampeonato}
                idTimes={idTimes}
              />
            </VStack>
          </form>
        </Box>
      ) : (
        <Box
          w={isLargerThan480 ? "40%" : "90%"}
          p={4}
          mt={4}
          borderWidth={1}
          borderRadius="lg"
          boxShadow="lg"
          backgroundColor="white"
          position="relative"
        >
          <IconButton
            colorScheme="green"
            icon={<CloseIcon />}
            isRound="true"
            position="absolute"
            top="1rem"
            left="1rem"
            onClick={() => navigate(-1)}
          />

          <Text fontSize="xl" textAlign="center" paddingBottom={5} mb={4}>
            Painel de Atletas
          </Text>

          <form onSubmit={handleSubmitChampionship(handleChampionshipSubmit)}>
            <FormControl
              id="championship"
              isInvalid={errorsChampionship.championship}
            >
              <FormLabel>Selecione um Campeonato</FormLabel>
              <Select
                paddingBottom={5}
                size="sm"
                {...registerChampionship("championship")}
                errorBorderColor="red.300"
                onChange={handleSelectChampionship}
              >
                <option value="">Selecione um campeonato</option>
                {teamOptions.map((championship) => (
                  <option key={championship.id} value={championship.id}>
                    {championship.nome}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errorsChampionship.championship?.message}
              </FormErrorMessage>
            </FormControl>
            <Button colorScheme="green" type="submit">
              Continuar
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};

// Função para converter o arquivo em base64
function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default PlayerCreationPage;
