import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import img2 from "../assets/images/a2.jpeg";
import {
  Button,
  Box,
  Input,
  useMediaQuery,
  Text,
  IconButton,
  Select,
  Flex,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useToast, ToastProvider } from "@chakra-ui/react";
import PaymentListModal from "../Components/paymentListModal";
import { http } from "../services/http";

const PaymentPage = () => {
  const toast = useToast();
  const [isLargerThan480] = useMediaQuery("(min-width: 1100px)");
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("pix");
  const [selectedParcel, setSelectedParcel] = useState("1x");
  const [valorJaPago, setValorJaPago] = useState(0);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedPlayerData, setSelectedPlayerData] = useState(null);
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [paymentData, setPaymentData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");

  const parcelOptions = {
    "1x": 190.0,
    "2x": 190.0,
    "1up": 380.0,
    "1uc": 380.0,
    "1c": 190.0,
    "2c": 190.0,
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    cpf: Yup.string()
      .required("CPF é obrigatório")
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido"),
  });
  const handleCloseListModal = () => {
    setIsListModalOpen(false);
  };
  const [SelectedTeamId, setSelectedTeamId] = useState("");

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const selectedParcelValue = parcelOptions[selectedParcel];
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedChampionshipId, setSelectedChampionshipId] = useState("");
  const handleOpenModal2 = (campeonatoId, timesId) => {
    setIdCampeonato(campeonatoId);
    setIdTimes(timesId);
    setIsModalOpen(true);
  };
  const [idCampeonato, setIdCampeonato] = useState(null);
  const [idTimes, setIdTimes] = useState(null);

  const handleParcelChange = (event) => {
    const option = event.target.value;
    setSelectedParcel(option);

    const numParcelas = parseInt(option.match(/\d+/)[0]);

    setPaymentData((prevData) => ({
      ...prevData,
      numParcela: numParcelas,
      valParcela: parcelOptions[option],
    }));
  };
  const handleOpenListModal = () => {
    setIsListModalOpen(true);
  };

  const loadTeams = async () => {
    try {
      const response = await http.get("/euro/api/times/listarTodos");
      const teams = response.data.conteudo.map((team) => ({
        id: team.idTime,
        nome: team.nomeTime,
      }));
      setTeams(teams);
    } catch (error) {
      console.error("Houve um erro!", error);
    }
  };

  useEffect(() => {
    loadTeams();
  }, []);

  const loadAthletes = async (teamId) => {
    try {
      const response = await http.get(
        `/euro/api/atletas/listar-atletas-time/${teamId}`
      );
      return response.data.conteudo;
    } catch (error) {
      console.error("Houve um erro!", error);
    }
  };

  const handleTeamSelect = async (teamId) => {
    setSelectedTeam(teamId);
    const athletes = await loadAthletes(teamId);
    setPlayers(athletes);
  };

  const handlePlayerSelect = async (playerId) => {
    try {
      const selectedPlayer = players.find(
        (player) => player.id === parseInt(playerId)
      );

      if (selectedPlayer) {
        setSelectedPlayer(selectedPlayer);

        const response = await http.get(`/euro/api/atletas/${playerId}`);
        const playerData = response.data.conteudo;

        setSelectedPlayerData(playerData);

        const paymentResponse = await http.get(
          `/euro/api/pagamento/listar-pgto-atleta/1/${playerId}`
        );
        const pagamentos = paymentResponse.data.conteudo;

        if (pagamentos && pagamentos.length > 0) {
          const totalPago = pagamentos.reduce(
            (total, pagamento) => total + pagamento.valParcela,
            0
          );
          setValorJaPago(totalPago);
          setPaymentData(pagamentos[pagamentos.length - 1]);
        } else {
          setValorJaPago(0);
          setPaymentData({});
        }
      } else {
        console.log("Nenhum jogador encontrado com o ID:", playerId);
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes do jogador:", error);
    }
  };

  const checkExistingPayment = async () => {
    try {
      const response = await http.get(
        `/euro/api/pagamento/listar-pgto-atleta/1/${selectedPlayer.id}`
      );

      return response.data.conteudo.length > 0
        ? response.data.conteudo[0]
        : null;
    } catch (error) {
      console.error("Erro ao verificar pagamento existente:", error);
      throw new Error("Erro ao verificar pagamento existente.");
    }
  };

  const handleContinue = async () => {
    try {
      if (selectedPlayer && selectedPlayer.id) {
        const existingPayment = await checkExistingPayment();
        const paymentDate = `${selectedDate}`;

        if (existingPayment) {
          const updatedPaymentData = {
            ...existingPayment,
            dataPgtoParcela: paymentDate,
            valParcela: existingPayment.valParcela + selectedParcelValue,
          };
          await http.put(`/euro/api/pagamento/altera`, updatedPaymentData);
          console.log("Pagamento atualizado com sucesso!");

          toast({
            title: "Atualizado com sucesso!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.log(
            "Nenhum pagamento encontrado para este jogador. Criando novo pagamento..."
          );
          const newPaymentData = {
            idAtleta: selectedPlayer.id,
            idTime: selectedTeam,
            idCamp: 1,
            numParcela: 1,
            dataPgtoParcela: paymentDate,
            valParcela: selectedParcelValue,
            ativo: true,
            formaPagamento: selectedPaymentOption,
          };
          await http.post("/euro/api/pagamento", newPaymentData);

          toast({
            title: "Adicionado com sucesso!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }

        setIsListModalOpen(false);
      } else {
        console.log("Nenhum jogador selecionado.");
      }
    } catch (error) {
      console.error("Erro ao processar pagamento:", error);
      toast({
        title: "Erro ao processar pagamento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDateChange = (event) => {
    const { value } = event.target;

    const cleanedValue = value.replace(/\D/g, "");

    const formattedValue = cleanedValue
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2");

    const limitedValue = formattedValue.slice(0, 10);

    setSelectedDate(limitedValue);
  };

  return (
    <Box
      backgroundImage={img2}
      backgroundSize="cover"
      backgroundPosition="center"
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Heading mb="2%" fontSize={["xl", "2xl", "3xl"]} color="white">
        Financeiro
      </Heading>
      <Box
        w={isLargerThan480 ? "40%" : "90%"}
        p={4}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        bg="whiteAlpha.900"
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <IconButton
            colorScheme="green"
            aria-label="Voltar"
            icon={<IoIosArrowBack />}
            onClick={() => navigate(-1)}
          />
        </Flex>

        <Flex>
          <Flex flex="1" pr={4}>
            <Select
              w="50%"
              placeholder="Selecione o time"
              onChange={(e) => handleTeamSelect(e.target.value)}
            >
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.nome}
                </option>
              ))}
            </Select>

            <Box flex="1" pr={4}>
              <Select
                placeholder="Selecione um jogador"
                onChange={(e) => handlePlayerSelect(e.target.value)}
              >
                {players.map((player) => (
                  <option key={player.id} value={player.id}>
                    {player.nomeAtleta}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        </Flex>
        <Flex>
          {selectedPlayerData && (
            <Box>
              <Box
                borderWidth={1}
                borderRadius="md"
                padding={2}
                marginBottom={2}
              >
                <Text fontSize="sm">Nome: {selectedPlayerData.nomeAtleta}</Text>
              </Box>
              <Box
                borderWidth={1}
                borderRadius="md"
                padding={2}
                marginBottom={2}
              >
                <Text fontSize="sm">CPF: {selectedPlayerData.documento}</Text>
              </Box>

              <Box mt={4}>
                <Text fontSize={["md", "lg"]} mt={4} mb={2}>
                  Selecione o pagamento a ser baixado
                </Text>

                <Select
                  value={selectedParcel}
                  onChange={handleParcelChange}
                  size="sm"
                  borderColor="grey"
                  focusBorderColor="green.300"
                  variant="filled"
                >
                  <option value="1x">1° parcela R$ 190,00 - Pix</option>
                  <option value="2x">2° parcela R$ 190,00 - Pix</option>
                  <option value="1up">Parcela Única R$ 380,00 - Pix</option>
                  <option value="1uc">Parcela Única R$ 380,00 - Cartão</option>
                  <option value="1c">1° parcela R$ 190,00 - Cartão</option>
                  <option value="2c">2° parcela R$ 190,00 - Cartão</option>
                </Select>

                <Text fontSize={["md", "lg"]} mt={4} mb={2}>
                  Selecione data do pagamento
                </Text>

                <Input
                  value={selectedDate}
                  onChange={handleDateChange}
                  size="sm"
                  borderColor="grey"
                  focusBorderColor="green.300"
                  variant="filled"
                  placeholder="DD/MM/AAAA"
                />

                {selectedDate && (
                  <Text mt={4} fontSize={["md", "lg"]} fontWeight="semibold">
                    Data selecionada: {selectedDate}
                  </Text>
                )}

                <Text fontSize={["md", "lg"]} mt={4} fontWeight="bold">
                  Valor pago: R$ {(valorJaPago || 0).toFixed(2)}
                </Text>
                <HStack
                  width="100%"
                  justifyContent="space-between"
                  textAlign="center"
                  alignItems="center"
                  mt={4}
                  spacing={20}
                >
                  <Button
                    w="100%"
                    colorScheme="green"
                    color="white"
                    onClick={handleContinue}
                  >
                    Confirmar
                  </Button>
                  <Button
                    onClick={handleOpenListModal}
                    colorScheme="yellow"
                    w="full"
                  >
                    Pagamentos
                  </Button>
                </HStack>

                <Box bg="red"></Box>
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
      <PaymentListModal
        isOpen={isListModalOpen}
        onClose={handleCloseListModal}
        SelectedTeam={SelectedTeamId}
      />
    </Box>
  );
};

export default PaymentPage;
