import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Button,
  Select,
  Text,
  Heading,
  Avatar,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  extendTheme,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Icon,
  useDisclosure,
  Stack,
  Grid,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaFutbol, FaRegCircle, FaSquare } from 'react-icons/fa';

import { http } from "../services/http";

const theme = extendTheme({
  colors: {
    darkGreen: {
      500: '#004d40',
    },
  },
});
function Mesa() {
  const [rodadaSelecionada, setRodadaSelecionada] = useState('');
  const [jogosRodada, setJogosRodada] = useState([]);
  const [jogoSelecionado, setJogoSelecionado] = useState(null);
  const [timeSelecionado, setTimeSelecionado] = useState('');
  const [atletasTimeA, setAtletasTimeA] = useState([]);
  const [atletasTimeB, setAtletasTimeB] = useState([]);
  const detalhesJogoSelecionado = jogosRodada.find(jogo => jogo.id === jogoSelecionado);

  const [eventos, setEventos] = useState([]);
  const [atletaSelecionado, setAtletaSelecionado] = useState(null);
  const [eventoSelecionado, setEventoSelecionado] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [eventoTemporario, setEventoTemporario] = useState(null);

  const handleClose = () => {
    onClose();
    setEventoTemporario(null);
    setTimeSelecionado('');
    setAtletaSelecionado(null);
    setEventoSelecionado('');
  };
  

  const prepararAdicionarEvento = (idTime, idAtleta, tipoEvento) => {
    // Converte o idAtleta para número, já que os IDs na lista de atletas são números
    const idAtletaNumerico = Number(idAtleta);

    // Encontra o atleta na lista apropriada usando o idAtletaNumerico
    const listaAtletas = idTime === 'A' ? atletasTimeA : atletasTimeB;
    const atleta = listaAtletas.find(atleta => atleta.id === idAtletaNumerico);

    if (!atleta) {
        console.error("Atleta não encontrado.");
        return;
    }

    // Obtém o primeiro nome do atleta para exibição
    const nomeAtleta = atleta.nomeAtleta.split(" ")[0];

    setEventoTemporario({
        idTime,
        idAtleta: idAtletaNumerico,
        tipoEvento,
        nomeAtleta // Armazena apenas o primeiro nome do atleta
    });
    onOpen();
    };

  
    const removerEvento = (index) => {
        const eventoRemovido = eventos[index];
    
        if (eventoRemovido.tipoEvento === 'gol') {
            const jogosAtualizados = jogosRodada.map(jogo => {
                if (jogo.id === jogoSelecionado) {
                    if (eventoRemovido.idTime === 'A') {
                        jogo.golsTimeA = Math.max(jogo.golsTimeA - 1, 0);
                    } else {
                        jogo.golsTimeB = Math.max(jogo.golsTimeB - 1, 0);
                    }
                }
                return jogo;
            });
            setJogosRodada(jogosAtualizados);
        }
    
        const eventosAtualizados = eventos.filter((_, i) => i !== index);
        setEventos(eventosAtualizados);
    };
    

  const confirmarAdicaoEvento = () => {
    if (eventoTemporario) {
        adicionarEvento(eventoTemporario.idTime, eventoTemporario.idAtleta, eventoTemporario.tipoEvento, eventoTemporario.nomeAtleta);
      setEventoTemporario(null);
      setTimeSelecionado('');
      setAtletaSelecionado(null);
      setEventoSelecionado('');
    }
    onClose(); 
  };
  

  const adicionarEvento = (idTime, idAtleta, tipoEvento, nomeAtleta) => {
    const novoEvento = { idTime, idAtleta, tipoEvento, nomeAtleta };
    setEventos(prevEventos => [...prevEventos, novoEvento]);

    // Resetar o atleta selecionado para null após adicionar o evento
    setAtletaSelecionado('');

    if (tipoEvento === 'gol') {
        const jogosAtualizados = jogosRodada.map(jogo => {
            if (jogo.id === jogoSelecionado) {
                if (idTime === 'A') {
                    jogo.golsTimeA += 1;
                } else {
                    jogo.golsTimeB += 1;
                }
            }
            return jogo;
        });
        setJogosRodada(jogosAtualizados);
    }
    // Fechar o modal após adicionar o evento
    onClose(); 
};


const encerrarPartida = async () => {
    try {
        const response = await http.get(`/euro/api/classificacao/1`);
        let classificacaoAtual = response.data.conteudo;

        const timeAId = Number(detalhesJogoSelecionado.idTimeA);
        const timeBId = Number(detalhesJogoSelecionado.idTimeB);

        const timeA = classificacaoAtual.find(c => c.classificacaoId.idTime === timeAId);
        const timeB = classificacaoAtual.find(c => c.classificacaoId.idTime === timeBId);

        if (!timeA || !timeB) {
            console.error("Um dos times não foi encontrado.");
            return;
        }

        const golsTimeA = detalhesJogoSelecionado.golsTimeA;  
        const golsTimeB = detalhesJogoSelecionado.golsTimeB;

        timeA.qtdJogos += 1;
        timeA.golsPro += golsTimeA;
        timeA.golsContra += golsTimeB;

        timeB.qtdJogos += 1;
        timeB.golsPro += golsTimeB;
        timeB.golsContra += golsTimeA;

        if (golsTimeA > golsTimeB) {
            timeA.vitoria += 1;
            timeA.pontos += 3;
            timeB.derrota += 1;
        } else if (golsTimeA < golsTimeB) {
            timeB.vitoria += 1;
            timeB.pontos += 3;
            timeA.derrota += 1;
        } else {
            timeA.empate += 1;
            timeA.pontos += 1;
            timeB.empate += 1;
            timeB.pontos += 1;
        }

        console.log("Classificação Atualizada do Time A:", timeA);
        console.log("Classificação Atualizada do Time B:", timeB);

        const golsPorAtleta = eventos.reduce((acc, evento) => {
            if (evento.tipoEvento === 'gol') {
                acc[evento.idAtleta] = (acc[evento.idAtleta] || 0) + 1;
            }
            return acc;
        }, {});

        const cartoesPorAtleta = eventos.reduce((acc, evento) => {
            if (evento.tipoEvento === 'amarelo' || evento.tipoEvento === 'vermelho') {
                if (!acc[evento.idAtleta]) {
                    acc[evento.idAtleta] = { amarelo: 0, vermelho: 0 };
                }
                acc[evento.idAtleta][evento.tipoEvento] += 1;
            }
            return acc;
        }, {});

        const bodyA = {
            classificacaoId: {
                idCampeonato: 1,
                idTime: timeAId
            },
            pontos: timeA.pontos,
            qtdJogos: timeA.qtdJogos,
            vitoria: timeA.vitoria,
            empate: timeA.empate,
            derrota: timeA.derrota,
            golsPro: timeA.golsPro,
            golsContra: timeA.golsContra,
            grupo: "string",
            numeroFase: 0
        };

        const bodyB = {
            classificacaoId: {
                idCampeonato: 1,
                idTime: timeBId
            },
            pontos: timeB.pontos,
            qtdJogos: timeB.qtdJogos,
            vitoria: timeB.vitoria,
            empate: timeB.empate,
            derrota: timeB.derrota,
            golsPro: timeB.golsPro,
            golsContra: timeB.golsContra,
            grupo: "string",
            numeroFase: 0
        };

        const jogoAtual = jogosRodada.find(jogo => jogo.id === jogoSelecionado);
        if (!jogoAtual) {
            console.error("Jogo selecionado não encontrado.");
            return;
        }

        const bodyJogo = {
            id: jogoAtual.id,
            idTimeA: jogoAtual.idTimeA,
            idTimeB: jogoAtual.idTimeB,
            idCampeonato: 1,
            dataJogo: jogoAtual.dataJogo.split('/').reverse().join('-'),
            horaJogo: jogoAtual.horaJogo,
            golsTimeA: golsTimeA,
            golsTimeB: golsTimeB,
            juiz:"string",
            observacao:"string",
            rodada:rodadaSelecionada,
            situacaoJogo: "3"
          };

          console.log("bodyJogo:" , bodyJogo)
    
          // Chamadas para atualização da classificação e do jogo (descomentar para uso real)
          await Promise.all([
            http.put(`/euro/api/classificacao/altera`, bodyA),
            http.put(`/euro/api/classificacao/altera`, bodyB),
            http.put(`/euro/api/jogos/altera`, bodyJogo)
          ]);


          const promessasDeGols = Object.entries(golsPorAtleta).map(([idAtleta, quantidade]) => {
            return {
                idJogos: jogoAtual.id,
                idCamp: 1,
                idAtleta: parseInt(idAtleta, 10),
                quantidade: quantidade
            };
        });

          console.log("Bodies para o serviço de gols:", promessasDeGols);

          // Prepara o corpo da requisição para o serviço de cartões
          const promessasDeCartoes = Object.entries(cartoesPorAtleta).map(([idAtleta, { amarelo, vermelho }]) => {
            return {
                idAtleta: parseInt(idAtleta, 10),
                idCampeonato: 1,
                cartaoTipo: "1",
                idJogos: jogoAtual.id,
                quantidadeParcialAmarelo: amarelo,
                quantidadeParcialVermelho: vermelho
            };
        });
            
           console.log("Bodies para o serviço de cartões:", promessasDeCartoes);
          
           promessasDeGols.forEach(bodyDeGol => http.post(`/euro/api/gols`, bodyDeGol));
           promessasDeCartoes.forEach(bodyDeCartao => http.put(`/euro/api/cartao/altera`, bodyDeCartao));

            
          // Espera pela conclusão de todas as promessas de gols e cartões
          await Promise.all([...promessasDeGols, ...promessasDeCartoes]);
    
          // Mensagem de sucesso (ajustar conforme necessário para sua UI)
          console.log("Partida encerrada com sucesso.");
          alert("Partida encerrada com sucesso."); // Exemplo de uso de alerta para feedback ao usuário

          setJogoSelecionado(null); // Limpa o jogo selecionado
          setTimeSelecionado(''); // Limpa o time selecionado
          setAtletaSelecionado(null); // Limpa o atleta selecionado
          setEventoSelecionado(''); // Limpa o evento selecionado
          setEventos([]); // Limpa os eventos registrados
    
        } catch (error) {
          console.error("Erro ao encerrar a partida:", error);
        }
    };
    

  
  

  const renderizarIconeEvento = (tipoEvento) => {
    switch (tipoEvento) {
      case 'gol':
        return <Icon as={FaFutbol} />;
      case 'amarelo':
        return <Icon as={FaRegCircle} color="yellow.400" />;
      case 'vermelho':
        return <Icon as={FaSquare} color="red.500" />;
      default:
        return null;
    }
  };


  useEffect(() => {
    async function buscarAtletas() {
      if (jogoSelecionado) {
        const jogo = jogosRodada.find(j => j.id === jogoSelecionado);
        if (jogo) {
          try {
            const [responseA, responseB] = await Promise.all([
              http.get(`/euro/api/atletas/listar-atletas-time/${jogo.idTimeA}`),
              http.get(`/euro/api/atletas/listar-atletas-time/${jogo.idTimeB}`)
            ]);
            setAtletasTimeA(responseA.data.conteudo);
            setAtletasTimeB(responseB.data.conteudo);
          } catch (error) {
            console.error("Erro ao buscar os atletas dos times:", error);
          }
        }
      }
    }

    buscarAtletas();
  }, [jogoSelecionado, jogosRodada]);
  

  useEffect(() => {
    const fetchJogosRodada = async () => {
      try {
        const response = await http.get(`/euro/api/jogos/listar-rodada/${rodadaSelecionada}`);
        console.log("response.data.conteudo:" , response.data.conteudo)
        setJogosRodada(response.data.conteudo || []);
      } catch (error) {
        console.error("Erro ao buscar os jogos da rodada:", error);
      }
    };

    if (rodadaSelecionada) {
      fetchJogosRodada();
    }
  }, [rodadaSelecionada]);

  useEffect(() => {
    // Implemente a busca dos jogadores quando um jogo é selecionado, se necessário
  }, [jogoSelecionado]);

  return (
    <ChakraProvider theme={theme}>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Evento
            </AlertDialogHeader>

            <AlertDialogBody>
                Tem certeza que deseja adicionar este evento?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="blue" onClick={confirmarAdicaoEvento} ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      <Box bg="white" width="100%" maxWidth={{ xl: "1200px" }} mx="auto" p={4} color="black">
        <Flex direction="column" gap="4">
          <Select placeholder="Selecione a rodada" value={rodadaSelecionada} onChange={(e) => setRodadaSelecionada(e.target.value)}>
            {Array.from({ length: 9 }, (_, i) => (
              <option key={i + 1} value={i + 1}>Rodada {i + 1}</option>
            ))}
            <option value={10}>Quartas-de-final</option>
            <option value={11}>Semi-final</option>
            <option value={12}>Final</option>
          </Select>

          {jogosRodada.length > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="darkGreen">
                Selecione um jogo
              </MenuButton>
              <MenuList>
                {jogosRodada.map((jogo) => (
                  <MenuItem key={jogo.id} onClick={() => setJogoSelecionado(jogo.id)}>
                    <Flex align="center">
                      <Avatar src={`data:image/jpeg;base64,${jogo.timeA.imagem}`} size="md" mr={2} />
                      <Text>{jogo.timeA.nomeTime} X {jogo.timeB.nomeTime}</Text>
                      <Avatar src={`data:image/jpeg;base64,${jogo.timeB.imagem}`} size="md" ml={2} />
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
            { jogoSelecionado && detalhesJogoSelecionado && (
                
              detalhesJogoSelecionado.situacaoJogo.idSituacaoJogo === 1 ? (
                <Box bg="white" p={7} mb={4} borderRadius="md" boxShadow="md" textAlign="center">
                    {jogosRodada.filter(jogo => jogo.id === jogoSelecionado).map((jogo) => (
                    <React.Fragment key={jogo.id}>
                        <Heading size={["md"]} mb="5">
                        {jogo.dataJogo} - {jogo.horaJogo}hs
                        </Heading>
                        <Flex align="center" justifyContent="center">
                        {jogo.timeA?.imagem ? (
                            <Avatar
                            src={`data:image/jpeg;base64,${jogo.timeA.imagem}`}
                            alt={jogo.timeA.nomeTime}
                            name={jogo.timeA.nomeTime}
                            size={{ base: "md", md: "lg" }} // Ajuste do tamanho do avatar
                            mr={4}
                            />
                        ) : (
                            <Avatar
                            name={jogo.timeA ? jogo.timeA.nomeTime : ' '}
                            size={{ base: "md", md: "lg" }} // Ajuste do tamanho para o avatar padrão
                            mr={4}
                            bg="gray.200" // Background do avatar padrão
                            />
                        )}
                        <Heading size={["xl", "2xl"]} mx={2}>
                            {jogo.golsTimeA} x {jogo.golsTimeB}
                        </Heading>
                        {jogo.timeB?.imagem ? (
                            <Avatar
                            src={`data:image/jpeg;base64,${jogo.timeB.imagem}`}
                            alt={jogo.timeB.nomeTime}
                            name={jogo.timeB.nomeTime}
                            size={{ base: "md", md: "lg" }} // Ajuste do tamanho do avatar
                            ml={4}
                            />
                        ) : (
                            <Avatar
                            name={jogo.timeB ? jogo.timeB.nomeTime : ' '}
                            size={{ base: "md", md: "lg" }} // Ajuste do tamanho para o avatar padrão
                            ml={4}
                            bg="gray.200" // Background do avatar padrão
                            />
                        )}
                        </Flex>
                    </React.Fragment>
                    ))}
                    {detalhesJogoSelecionado && (
                        <Box bg="white" p={7} mb={4} borderRadius="md" boxShadow="md" textAlign="center">
                            <Box>
                                <Select placeholder="Selecione o Time" value={timeSelecionado} onChange={(e) => setTimeSelecionado(e.target.value)}>
                                <option value="A">{detalhesJogoSelecionado.timeA.nomeTime}</option>
                                <option value="B">{detalhesJogoSelecionado.timeB.nomeTime}</option>
                                </Select>


                                <Select
                                    key={`select-atleta-${timeSelecionado}`}
                                    placeholder="Selecione o Atleta"
                                    value={atletaSelecionado}
                                    onChange={(e) => setAtletaSelecionado(e.target.value)}
                                    >
                                    {(timeSelecionado === 'A' ? atletasTimeA : atletasTimeB).map(atleta => (
                                        <option key={atleta.id} value={atleta.id}>{atleta.nomeAtleta}</option>
                                    ))}
                                </Select>

                                <Select placeholder="Selecione o Evento" value={eventoSelecionado} onChange={(e) => setEventoSelecionado(e.target.value)}>
                                <option value="gol">Gol</option>
                                <option value="amarelo">Cartão Amarelo</option>
                                <option value="vermelho">Cartão Vermelho</option>
                                </Select>
                            </Box>
                        </Box>
                        )}

                    <Flex direction="column">
                        {eventos.map((evento, index) => (
                        <Flex key={index} justify={evento.idTime === 'A' ? 'flex-start' : 'flex-end'}>
                            {renderizarIconeEvento(evento.tipoEvento)}
                            <Text ml={2}>{evento.nomeAtleta} - {evento.tipoEvento}</Text>
                        </Flex>
                        ))}
                    </Flex>

                    
                    <Box bg="white" p={7} mb={4} borderRadius="md" boxShadow="md" textAlign="center">
                        <Button
                            colorScheme="darkGreen"
                            onClick={() => prepararAdicionarEvento(timeSelecionado, atletaSelecionado, eventoSelecionado)}
                        >
                            Adicionar Evento
                        </Button>

                        <Button marginTop={1} marginLeft={1} colorScheme="red" onClick={encerrarPartida}>Encerrar Partida</Button>

                    </Box>

                    <Flex direction="column" >

                        {eventos.map((evento, index) => (
                            <Flex key={index} justify={evento.idTime === 'A' ? 'flex-start' : 'flex-end'} align="center" marginBottom={2}>
                                {renderizarIconeEvento(evento.tipoEvento)}
                                <Text ml={2}>{evento.nomeAtleta} - {evento.tipoEvento}</Text>
                                <Button ml="4" size="sm" colorScheme="red" onClick={() => removerEvento(index)}>
                                    Remover
                                </Button>
                            </Flex>
                        ))}
                    </Flex>


                </Box>
              ) : (
                <Text textAlign="center" mt={4} fontSize="lg" fontWeight="bold">
                  Jogo não permitido para inserir eventos.
                </Text>
              )
            )}

           {/* Implemente aqui a UI para seleção de jogadores e eventos */}
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default Mesa;
