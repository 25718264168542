// CampeonatoModal.js
import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, InputGroup, Input, InputRightElement, Button, SimpleGrid, Box, Text } from '@chakra-ui/react';
import axios from 'axios';
import { http } from '../services/http';

const CampeonatoModal = ({ isOpen, onClose }) => {
  const [campeonatos, setCampeonatos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchCampeonatos();
  }, []);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const results = campeonatos.filter(campeonato =>
      campeonato.nomeCampeonato.toLowerCase().includes(lowercasedSearchTerm) ||
      campeonato.categoria.toLowerCase().includes(lowercasedSearchTerm)
    );
    setSearchResults(results);
  }, [searchTerm, campeonatos]);

  const fetchCampeonatos = async () => {
    try {
       const response = await http.get('/euro/api/campeonatos/listarTodos');
      setCampeonatos(response.data.conteudo);
      setSearchResults(response.data.conteudo);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Listar Campeonatos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputGroup mb={4}>
            <Input
              placeholder="Search Campeonatos"
              value={searchTerm}
              onChange={handleChange}
            />
            <InputRightElement>
              <Button size="sm">Buscar</Button>
            </InputRightElement>
          </InputGroup>
          {searchResults.length > 0 ? searchResults.map((campeonato, index) => (
            <Box key={index} borderWidth="1px" borderRadius="lg" p="6" mb="4">
              <SimpleGrid columns={2} spacing={10}>
                <Box>
                  <Text><b>Nome:</b> {campeonato.nomeCampeonato}</Text>
                  <Text><b>Categoria:</b> {campeonato.categoria}</Text>
                </Box>
                <Box>
                  <Text><b>Idade mínima:</b> {campeonato.idadeMinima}</Text>
                  <Text><b>Idade máxima:</b> {campeonato.idadeMaxima}</Text>
                </Box>
              </SimpleGrid>
            </Box>
          )) : <Box>Não existem campeonatos cadastrados.</Box>}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CampeonatoModal;
