import React, { useState ,  useRef  } from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  List,
  ListItem,
  Text,
  HStack,
  IconButton,
  Icon,
  Select,
  Checkbox,
  Center,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import img1 from "../assets/images/a2.jpeg";
import { IoIosArrowBack } from "react-icons/io";
import { PDFDocument, rgb } from 'pdf-lib';


function TabelaGerada() {
  const [quantidadeTimes, setQuantidadeTimes] = useState(0);
  const [quantidadeGrupos, setQuantidadeGrupos] = useState(1);
  const [jogosDentroDoGrupo, setJogosDentroDoGrupo] = useState(true);
  const [grupos, setGrupos] = useState([]);
  const [tabelaConfrontos, setTabelaConfrontos] = useState([]);
  const contentRef = useRef(null);

  // Função para embaralhar um array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  async function gerarPDFTabela(grupos,  tabelaConfrontos) {
    try {
    // Crie um novo documento PDF em branco
    const pdfDoc = await PDFDocument.create();
    
    // Defina a fonte e tamanho da fonte
    const font = await pdfDoc.embedFont('Helvetica');
    
    // Função para adicionar uma nova página ao PDF
    const adicionarPagina = () => {
      const page = pdfDoc.addPage([595, 842]); // Tamanho A4 em pontos (1 ponto = 1/72 polegadas)
      return { page, yOffset: page.getSize().height - 100 };
    };
    
    let { page, yOffset } = adicionarPagina(); // Inicialmente, crie a primeira página
    
    const fontSize = 15;
    
    // Cabeçalho com o título
    page.drawText('Tabela Gerada', {
      x: 50,
      y: page.getSize().height - 50,
      size: fontSize + 10,
      font: font,
      color: rgb(0, 0, 0),
    });
  
    // Insira os dados dos times, grupos e tabela de confrontos no PDF, adicionando novas páginas conforme necessário
    for (const grupoIndex in grupos) {
      const grupo = grupos[grupoIndex];
      if (yOffset < 100) {
        // Se o espaço restante na página atual for insuficiente, crie uma nova página
        const novaPagina = adicionarPagina();
        page = novaPagina.page;
        yOffset = novaPagina.yOffset;
      }
  
      page.drawText(`Grupo ${parseInt(grupoIndex) + 1}`, {
        x: 50,
        y: yOffset,
        size: fontSize + 5,
        font: font,
        color: rgb(0, 0, 0),
      });
  
      yOffset -= 20;
  
      for (const time of grupo) {
        if (yOffset < 20) {
          // Se o espaço restante na página atual for insuficiente, crie uma nova página
          const novaPagina = adicionarPagina();
          page = novaPagina.page;
          yOffset = novaPagina.yOffset;
        }
  
        page.drawText(`Time: ${time}`, {
          x: 50,
          y: yOffset,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
  
        yOffset -= 20;
      }
  
      yOffset -= 20;
    }
  
    yOffset -= 20;
  
    // Adicione a tabela de confrontos
    page.drawText('Tabela de Confrontos', {
      x: 50,
      y: yOffset,
      size: fontSize + 5,
      font: font,
      color: rgb(0, 0, 0),
    });
  
    yOffset -= 20;
  
    for (const confronto of tabelaConfrontos) {
      if (yOffset < 20) {
        // Se o espaço restante na página atual for insuficiente, crie uma nova página
        const novaPagina = adicionarPagina();
        page = novaPagina.page;
        yOffset = novaPagina.yOffset;
      }
  
      page.drawText(`Jogo ${confronto.numero}: ${confronto.time1} x ${confronto.time2}`, {
        x: 50,
        y: yOffset,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
  
      yOffset -= 20;
    }
  
    // Serialize o documento PDF em um ArrayBuffer
    const pdfBytes = await pdfDoc.save();
  
    // Crie um Blob a partir do ArrayBuffer
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  
    // Crie um URL para o Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Abra o PDF em uma nova janela
    window.open(pdfUrl);
    } catch (error) {
        console.error('Erro ao gerar PDF:', error);
      }
    }
  
  

  // Função para realizar o sorteio dos times e confrontos
  const realizarSorteio = () => {
    if (quantidadeTimes <= 0 || quantidadeGrupos <= 0) {
      alert('Informe valores válidos.');
      return;
    }

    // Gerar times
    const times = Array.from({ length: quantidadeTimes }, (_, i) => `Time ${i + 1}`);
    const timesEmbaralhados = shuffleArray(times);

    // Distribuir times em grupos
    const grupos = Array.from({ length: quantidadeGrupos }, () => []);
    let grupoIndex = 0;

    for (const time of timesEmbaralhados) {
      grupos[grupoIndex].push(time);
      grupoIndex = (grupoIndex + 1) % quantidadeGrupos;
    }

    setGrupos(grupos);

    // Embaralhar grupos
    const gruposEmbaralhados = shuffleArray(grupos);

    // Gerar confrontos
    const confrontos = [];

    if (jogosDentroDoGrupo) {
      for (let i = 0; i < quantidadeGrupos; i++) {
        const grupo = gruposEmbaralhados[i];
        const confrontosGrupo = [];

        for (let j = 0; j < grupo.length - 1; j++) {
          for (let k = j + 1; k < grupo.length; k++) {
            confrontosGrupo.push({
              time1: grupo[j],
              time2: grupo[k],
            });
          }
        }

        confrontos.push(...shuffleArray(confrontosGrupo));
      }
    } else {
      for (let i = 0; i < quantidadeGrupos - 1; i++) {
        for (let j = i + 1; j < quantidadeGrupos; j++) {
          const grupo1 = gruposEmbaralhados[i];
          const grupo2 = gruposEmbaralhados[j];
          const confrontosGrupo = [];

          for (let k = 0; k < grupo1.length; k++) {
            for (let l = 0; l < grupo2.length; l++) {
              confrontosGrupo.push({
                time1: grupo1[k],
                time2: grupo2[l],
              });
            }
          }

          confrontos.push(...shuffleArray(confrontosGrupo));
        }
      }
    }

    // Adicionar números de identificação aos confrontos
    const confrontosNumerados = confrontos.map((confronto, index) => ({
      ...confronto,
      numero: index + 1,
    }));

    setTabelaConfrontos(confrontosNumerados);
  };

  // Função para verificar se a quantidade de times é ímpar e definir a opção de jogos dentro do grupo
  const verificarQuantidadeTimes = (value) => {
    setQuantidadeTimes(value);

    // Verifica se a quantidade de times é ímpar
    if (value % 2 === 1) {
      setJogosDentroDoGrupo(true); // Define jogos dentro do grupo como verdadeiro
    }
  };

    // Função para limpar os dados
    const limparSorteio = () => {
        setQuantidadeTimes(0);
        setQuantidadeGrupos(1);
        setJogosDentroDoGrupo(true);
        setGrupos([]);
        setTabelaConfrontos([]);
      };
 
      return (
        <Center bgImage={`url(${img1})`} bgSize='cover' bgPosition='center' flex="1"  >

         <Box
          w={["100%", "60%"]}
          p={4}
          mt={4}
          borderWidth={1}
          borderRadius="lg"
          boxShadow="lg"
          mx="auto"
          backgroundColor="white"
          position="relative"
        >
          <IconButton 
           colorScheme="green" 
            icon={<IoIosArrowBack />} 
            isRound='true' 
            position='absolute' 
            top='1rem' 
            left='1rem' 
            onClick={() => window.history.back()}
          />
            <Heading size="lg" textAlign="center" mb={4}>
              Gerar Tabela - Olympique
            </Heading>
            <Flex mb={4}>
              <Box mr={4}>
                <label>Quantidade de Times:</label>
                <Input
                  type="number"
                  value={quantidadeTimes}
                  onChange={(e) => verificarQuantidadeTimes(e.target.value)}
                />
              </Box>
              <Box mr={4}>
                <label>Quantidade de Grupos:</label>
                <Select
                  value={quantidadeGrupos}
                  onChange={(e) => setQuantidadeGrupos(e.target.value)}
                >
                  {Array.from({ length: 10 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex>
            <Button colorScheme="blue" onClick={realizarSorteio} margin={3}>
              Gerar Tabela
            </Button>
            <Button colorScheme="blue" onClick={() => gerarPDFTabela(grupos, tabelaConfrontos)} margin={3}>
                Gerar PDF
            </Button>
            <Button colorScheme="red" onClick={limparSorteio} margin={3}>
              Limpar 
            </Button>            
            <Checkbox
              isChecked={jogosDentroDoGrupo || quantidadeTimes % 2 === 1}
              isDisabled={quantidadeTimes % 2 === 1}
              onChange={() => {
                if (quantidadeTimes % 2 === 1) {
                  return;
                }
                setJogosDentroDoGrupo(!jogosDentroDoGrupo);
              }}
              margin={5}
            >
              Jogos Dentro do Grupo
            </Checkbox>
    
            <Heading size="lg" mt={4}>
              Grupos e Confrontos
            </Heading>
            <Flex mt={4}>
              {grupos.map((grupo, index) => (
               <Box key={index} mr={4}>
                    <Heading size="md" mb={2}>
                      Grupo {index + 1}
                    </Heading>
                    <List>
                      {grupo.map((time, timeIndex) => (
                      <ListItem key={timeIndex}>{time}</ListItem>
                    ))}
                    </List>
                </Box>
            ))}
            </Flex>
                <Heading size="lg" mt={4}>
                    Tabela de Confrontos
                </Heading>
            <List mt={4}>
            {tabelaConfrontos.map((confronto, index) => (
                <ListItem key={index}>
                     Jogo {confronto.numero}: {confronto.time1} x {confronto.time2}
                </ListItem>
            ))}
            </List>
          </Box>
        </Center>
      );
    }

export default TabelaGerada;
