import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AuthenticatedNavMock } from "../../../routes/authenticated";

function Sidebar({ isOpen, onClose }) {
  const navigate = useNavigate();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Flex
            width="full"
            height="full"
            alignItems="center"
            justifyContent="center"
          >
            <VStack width="full" spacing={0}>
              {AuthenticatedNavMock.map(({ icon: Icon, label, path, navs }, index) => (
                navs ? (
                  <Accordion key={index} width="full" allowToggle>
                    <AccordionItem width="full" border="none">
                      <AccordionButton paddingInline={5} height="10" _hover={{
                        backgroundColor: "blackAlpha.50",
                        color: "body.1",
                        cursor: "pointer",
                      }}>
                        <HStack width="full" height="10" alignItems="center" justifyContent="start">
                          <Icon size={20} />
                          <Text fontWeight="medium">{label}</Text>
                        </HStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        {navs.map(({ icon: Icon, label, path }, index) => (
                          <HStack
                            key={index}
                            width="full"
                            height="10"
                            paddingInline={5}
                            alignItems="center"
                            justifyContent="start"
                            _hover={{
                              backgroundColor: "blackAlpha.50",
                              color: "body.1",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!path) return;
                              navigate(path);
                              onClose(); // Fecha o Drawer após a navegação
                            }}
                          >
                            <Icon size={20} />
                            <Text fontWeight="medium">{label}</Text>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ) : (
                  <HStack
                    key={index}
                    width="full"
                    height="10"
                    paddingInline={5}
                    alignItems="center"
                    justifyContent="start"
                    _hover={{
                      backgroundColor: "blackAlpha.50",
                      color: "body.1",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (!path) return;
                      navigate(path);
                      onClose(); // Fecha o Drawer após a navegação
                    }}
                  >
                    <Icon size={20} />
                    <Text fontWeight="medium">{label}</Text>
                  </HStack>
                )
              ))}
            </VStack>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Sidebar;
