import React from "react";
import { Box, Heading, Text, Stack, Container, VStack } from "@chakra-ui/react";

function PrivacyPolicy() {
  return (
    <Box bg="#38a169" py={10} px={4}>
      <Container maxW="container.md" bg="white" p={6} rounded="lg" shadow="lg">
      <Text fontSize="3xl" mb={4} textAlign="center" fontWeight="bold" color="black" textShadow="1px 1px #333">
          POLÍTICA DE PRIVACIDADE DA EURO SOLUÇÕES
        </Text>

        <Stack spacing={4} mt={6}>
          <Text fontSize="xl">
            <strong>1. Informações Coletadas</strong>
          </Text>
          <Text fontSize="md">
            Ao utilizar nossos Serviços, coletamos as seguintes informações
            pessoais:
          </Text>
          <Text fontSize="md">
            <strong>1.1</strong> - Informações de Registro: Quando você se
            inscreve em um campeonato ou cria uma conta em nossos Serviços,
            coletamos seu nome, endereço de e-mail, número de telefone e outras
            informações de contato.
          </Text>
          <Text fontSize="md">
            <strong>1.2</strong> - Informações de Pagamento: Para facilitar
            transações relacionadas aos campeonatos, coletamos informações
            financeiras, como números de cartão de crédito ou outros detalhes de
            pagamento.
          </Text>
          <Text fontSize="md">
            <strong>1.3</strong> - Dados do Jogo: Ao participar de nossos
            eventos esportivos, registramos informações sobre as partidas,
            incluindo placares, estatísticas do jogo e outros dados relevantes.
          </Text>
          <Text fontSize="md">
            <strong>1.4</strong> - Dados de Localização: Se você optar por
            compartilhar sua localização conosco, coletamos informações de
            localização por meio de nossos aplicativos e serviços.
          </Text>
        </Stack>
        <Stack spacing={4} mt={6}>
          <Text fontSize="xl">
            <strong>2. Uso das Informações</strong>
          </Text>
          <Text fontSize="md">
            Utilizamos suas informações pessoais para os seguintes propósitos:
          </Text>
          <Text fontSize="md">
            <strong>2.1</strong> - Prestação de Serviços: As informações
            coletadas são utilizadas para organizar e gerenciar os campeonatos
            de futebol e eventos esportivos relacionados.
          </Text>
          <Text fontSize="md">
            <strong>2.2</strong> - Comunicações: Mantemos contato com você para
            enviar notificações importantes sobre campeonatos, informações de
            conta e atualizações de nossos serviços.
          </Text>
          <Text fontSize="md">
            <strong>2.3</strong> - Melhorias nos Serviços: Utilizamos dados
            agregados e anônimos para aprimorar a qualidade e a funcionalidade
            dos nossos serviços.
          </Text>
          <Text fontSize="md">
            <strong>2.4</strong> - Processamento de Pagamentos: Para facilitar
            transações, utilizamos suas informações financeiras.
          </Text>
        </Stack>
        <Stack spacing={4} mt={6}>
          <Text fontSize="xl">
            <strong>3. Compartilhamento de Informações</strong>
          </Text>
          <Text fontSize="md">
            Sua privacidade é importante para nós, e não compartilhamos suas
            informações pessoais com terceiros, exceto nas seguintes
            circunstâncias:
          </Text>
          <Text fontSize="md">
            <strong>3.1</strong> - Parceiros de Negócios: Compartilhamos
            informações com parceiros de negócios que nos auxiliam na prestação
            dos nossos serviços.
          </Text>
          <Text fontSize="md">
            <strong>3.2</strong> - Cumprimento Legal: Caso seja necessário,
            podemos divulgar informações pessoais para cumprir obrigações legais
            ou regulatórias.
          </Text>
          <Text fontSize="xl">
            <strong>4. Segurança das Informações</strong>
          </Text>
        </Stack>
        <Text fontSize="md" mt={6}>
          <strong>4.1</strong> - Implementamos medidas rigorosas para proteger
          suas informações pessoais contra acessos não autorizados e divulgações
          indevidas.
        </Text>
        <Text fontSize="xl" mt={6}>
          <strong>5. Seus Direitos</strong>
        </Text>
        <Text fontSize="md" mt={6}>
          <strong>5.1</strong> - Você tem o direito de acessar, corrigir ou
          excluir suas informações pessoais a qualquer momento. Entre em contato
          conosco para exercer esses direitos.
        </Text>
        <Text fontSize="xl" mt={6}>
          <strong>6. Alterações na Política de Privacidade</strong>
        </Text>
        <Text fontSize="md" mt={6}>
          <strong>6.1</strong> - Esta Política de Privacidade pode ser
          atualizada periodicamente. A data da última atualização será sempre
          indicada na parte superior deste documento.
        </Text>
        <Text fontSize="xl" mt={6}>
          <strong>7. Contato</strong>
        </Text>
        <Text fontSize="md" mt={6}>
          <strong>7.1</strong> - Para dúvidas, preocupações ou solicitações
          relacionadas à privacidade, estamos à disposição em euro@eurosolucoesesportivas.com.br
        </Text>
        <Text fontSize="md" mt={6} textAlign="center">
          Ao utilizar nossos Serviços, você concorda com esta Política de
          Privacidade. Certifique-se de revisar esta política regularmente para
          ficar informado sobre eventuais alterações.
        </Text>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
