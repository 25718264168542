import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useMediaQuery,
  Text,
  Flex,
  Select,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import AthleteDetailsModal from "../Components/athleteDetailsModal";
import { IconButton } from "@chakra-ui/react";
import AthletesListModal from "../Components/athletesListModal";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import img2 from "../assets/images/img4.png";
import { http } from "../services/http";

const PlayerCreationPage = () => {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [SelectedTeamId, setSelectedTeamId] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const [athleteData, setAthleteData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [athletesByTeam, setAthletesByTeam] = useState([]);

  const handleOpenListModal = () => {
    setIsListModalOpen(true);
  };

  const handleCloseListModal = () => {
    setIsListModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    nomeCompleto: Yup.string().required("Nome do atleta é obrigatório"),
    cpf: Yup.string().required("CPF é obrigatório"),
    dataNascimento: Yup.string().required("Data de nascimento é obrigatória"),
    posicao: Yup.string().required("Posição do atleta é obrigatória"),
    time: Yup.string().required("Time é obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      time: "",
      posicao: "",
    },
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const listAthletesByTeam = async (SelectedTeamId) => {
    try {
      const response = await http.get(
        `/euro/api/atletas/listar-atletas-time/${SelectedTeamId}`
      );
      setAthletesByTeam(response.data.conteudo);
      handleOpenListModal(); // Abrir o modal aqui
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await http.get("/euro/api/times/listarTodos");
        const teams = response.data.conteudo.map((team) => ({
          id: team.idTime,
          nome: team.nomeTime,
        }));
        setTeamOptions(teams);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchTeams();
  }, []);

  const handleSubmission = async (data) => {
    try {
      if (selectedFile && !(selectedFile instanceof Blob)) {
        console.error("Arquivo inválido.");
        return;
      }

      let imageBase64 = null;
      if (selectedFile) {
        imageBase64 = await toBase64(selectedFile);
      } else {
        imageBase64 = " ";
      }

      const athleteData = {
        idTimes: SelectedTeamId,
        nomeAtleta: data.nomeCompleto,
        documento: data.cpf,
        dataNasc: data.dataNascimento,
        telAtleta: "",
        posicaoAtleta: data.posicao,
        imagem: imageBase64,
      };

      console.log("athleteData", athleteData);
      const response = await http.post("/euro/api/atletas", athleteData);
      const createdAtletaId = response.data.conteudo.id;

      toast({
        title: "Inclusão bem-sucedida.",
        description: "Atleta inserido com sucesso na sua equipe.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      reset({
        nomeCompleto: "",
        cpf: "",
        dataNascimento: "",
        posicao: "",
        time: "",
      });
      // Limpa a seleção do arquivo
      setSelectedFile(null);
      setIsFilePicked(false);
      // navigate(-1);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  function getIdFromName(name) {
    const team = teamOptions.find((team) => team.nome === name);
    return team ? team.id : "";
  }

  const handleSelectTeam = (event) => {
    const teamId = event.target.value;
    setSelectedTeamId(teamId);
    console.log("ID: ", teamId);

    setValue("time", event.target.value);
  };

  // const handleSelectTeam = (event) => {
  //   const teamId = event.target.value;
  //   setValue('time', teamId);
  // };

  const cpfMask = "999.999.999-99";
  const dateMask = "99/99/9999";

  const handleImageChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <Box
      backgroundImage={img2}
      backgroundSize="cover"
      backgroundPosition="center"
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        bg="whiteAlpha.900"
        w={isLargerThan480 ? "40%" : "80%"}
        p={4}
        mt={4}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        position="relative"
      >
        <IconButton
          colorScheme="green"
          aria-label="Voltar"
          icon={<IoIosArrowBack />}
          onClick={() => navigate(-1)}
          position="absolute" // Coloque o IconButton em posição absoluta.
          top={2} // Ajuste a posição vertical como preferir.
          left={2} // Ajuste a posição horizontal como preferir.
        />

        <Text fontSize="xl" textAlign="center" mb={4}>
          Painel de Atletas
        </Text>

        <form onSubmit={handleSubmit(handleSubmission)}>
          <FormControl id="nomeCompleto" isInvalid={errors.nomeCompleto}>
            <FormLabel>Nome Completo</FormLabel>
            <Input
              size="sm"
              type="text"
              {...register("nomeCompleto")}
              errorBorderColor="red.300"
            />
            <FormErrorMessage>{errors.nomeCompleto?.message}</FormErrorMessage>
          </FormControl>

          <Flex mt={4}>
            <FormControl id="cpf" flex="1" isInvalid={errors.cpf}>
              <FormLabel>CPF</FormLabel>
              <InputMask
                mask={cpfMask}
                value={watch("cpf")}
                onChange={(e) => setValue("cpf", e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    size="sm"
                    type="text"
                    errorBorderColor="red.300"
                  />
                )}
              </InputMask>
              <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="dataNascimento"
              flex="1"
              ml={4}
              isInvalid={errors.dataNascimento}
            >
              <FormLabel>Data de Nasc</FormLabel>
              <InputMask
                mask={dateMask}
                value={watch("dataNascimento")}
                onChange={(e) => setValue("dataNascimento", e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    size="sm"
                    type="text"
                    errorBorderColor="red.300"
                  />
                )}
              </InputMask>
              <FormErrorMessage>
                {errors.dataNascimento?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex mt={4}>
            <FormControl id="time" flex="1" isInvalid={errors.time}>
              <FormLabel>Time</FormLabel>
              <Select
                size="sm"
                {...register("time")}
                errorBorderColor="red.300"
                onChange={handleSelectTeam}
              >
                <option value="">Selecione um time</option>
                {teamOptions.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.nome}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="posicao"
              flex="1"
              ml={4}
              isInvalid={errors.posicao}
            >
              <FormLabel>Posição</FormLabel>
              <Select
                size="sm"
                {...register("posicao")}
                errorBorderColor="red.300"
              >
                <option value="">Selecione uma posição</option>
                <option value="Goleiro">Goleiro</option>
                <option value="Zagueiro">Zagueiro</option>
                <option value="Ala">Ala</option>
                <option value="Volante">Volante</option>
                <option value="Meia">Meia</option>
                <option value="Pivô">Pivô</option>
              </Select>
              <FormErrorMessage>{errors.posicao?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl id="file" mt={4}>
            <FormLabel>Upload de Imagem</FormLabel>
            <Input
              size="sm"
              type="file"
              onChange={handleImageChange}
              errorBorderColor="red.300"
            />
            <FormErrorMessage>{errors.file?.message}</FormErrorMessage>
          </FormControl>

          {isFilePicked ? (
            <div>
              <p>Filename: {selectedFile ? selectedFile.name : ""}</p>
              <p>Filetype: {selectedFile ? selectedFile.type : ""}</p>
              <p>Size in bytes: {selectedFile ? selectedFile.size : ""}</p>
            </div>
          ) : (
            <p>Select a file to show details</p>
          )}

          <VStack spacing={4} mt={4} align="stretch">
            <Button colorScheme="green" type="submit">
              Enviar
            </Button>
            <Button colorScheme="green" onClick={handleOpenModal}>
              Consultar
            </Button>
            <AthleteDetailsModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
            />
            <Button colorScheme="green" onClick={handleOpenListModal}>
              Listar Atletas por Time
            </Button>
            <AthletesListModal
              isOpen={isListModalOpen}
              onClose={handleCloseListModal}
              SelectedTeam={SelectedTeamId}
            />
            <NavLink to="/playerUpdatePage">
              <Button colorScheme="green">Alterar Atleta</Button>
            </NavLink>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

// Função para converter o arquivo em base64
function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default PlayerCreationPage;
