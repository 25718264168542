import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Flex,
  List,
  ListItem,
  Text,
  HStack,
  IconButton,
  Icon,
  Center,
} from '@chakra-ui/react';

import { NavLink } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { PDFDocument, rgb } from 'pdf-lib';
import img1 from "../assets/images/a2.jpeg";
import { IoIosArrowBack } from "react-icons/io";

async function gerarPDFSorteio(jogadores, times) {
    // Crie um novo documento PDF em branco
    const pdfDoc = await PDFDocument.create();
    
    // Defina a fonte e tamanho da fonte
    const font = await pdfDoc.embedFont('Helvetica');
    
    // Função para adicionar uma nova página ao PDF
    const adicionarPagina = () => {
      const page = pdfDoc.addPage([595, 842]); // Tamanho A4 em pontos (1 ponto = 1/72 polegadas)
      return { page, yOffset: page.getSize().height - 100 };
    };
    
    let { page, yOffset } = adicionarPagina(); // Inicialmente, crie a primeira página
    
    const fontSize = 15;
    
    // Cabeçalho com o título
    page.drawText('Sorteio de Times', {
      x: 50,
      y: page.getSize().height - 50,
      size: fontSize + 10,
      font: font,
      color: rgb(0, 0, 0),
    });
  
    // Insira os dados dos times e jogadores no PDF, adicionando novas páginas conforme necessário
    for (const time of times) {
      if (yOffset < 100) {
        // Se o espaço restante na página atual for insuficiente, crie uma nova página
        const novaPagina = adicionarPagina();
        page = novaPagina.page;
        yOffset = novaPagina.yOffset;
      }
  
      page.drawText(`Time: ${time.nome}`, {
        x: 50,
        y: yOffset,
        size: fontSize + 5,
        font: font,
        color: rgb(0, 0, 0),
      });
  
      yOffset -= 20;
  
      for (const jogador of time.jogadores) {
        if (yOffset < 20) {
          // Se o espaço restante na página atual for insuficiente, crie uma nova página
          const novaPagina = adicionarPagina();
          page = novaPagina.page;
          yOffset = novaPagina.yOffset;
        }
  
        page.drawText(`Nome: ${jogador.nome}, Nota: ${jogador.nota}, Idade: ${jogador.idade}`, {
          x: 50,
          y: yOffset,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
  
        yOffset -= 20;
      }
  
      yOffset -= 20;
    }
  
    // Serialize o documento PDF em um ArrayBuffer
    const pdfBytes = await pdfDoc.save();
  
    // Crie um Blob a partir do ArrayBuffer
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  
    // Crie um URL para o Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Abra o PDF em uma nova janela
    window.open(pdfUrl);
  }
  
  

function StarRating({ value, onChange }) {
  const stars = [1, 2, 3, 4, 5];

  return (
    <HStack spacing={1}>
      {stars.map((star) => (
        <Icon
          as={FaStar}
          key={star}
          boxSize={5}
          color={star <= value ? 'yellow.400' : 'gray.200'}
          cursor="pointer"
          onClick={() => onChange(star)}
        />
      ))}
    </HStack>
  );
}

function SorteioPage() {
  const [quantidadeTotalJogadores, setQuantidadeTotalJogadores] = useState(0);
  const [quantidadeTimes, setQuantidadeTimes] = useState(0);
  const [jogadores, setJogadores] = useState([]);
  const [times, setTimes] = useState([]);

  // Função para adicionar um jogador à lista
  const adicionarJogador = () => {
    const novoJogador = { nome: '', nota: 0, idade: '' };
    setJogadores([...jogadores, novoJogador]);
    setQuantidadeTotalJogadores(jogadores.length + 1); // Atualiza a quantidade total
  };

  // Função para remover um jogador da lista
  const removerJogador = (index) => {
    const novosJogadores = [...jogadores];
    novosJogadores.splice(index, 1);
    setJogadores(novosJogadores);
    setQuantidadeTotalJogadores(novosJogadores.length); // Atualiza a quantidade total
  };

  // Função para realizar o sorteio
  const realizarSorteio = () => {
    if (quantidadeTotalJogadores <= 0 || quantidadeTimes <= 0) {
      alert('Informe valores válidos.');
      return;
    }

    // Verifique se todos os jogadores forneceram informações
    for (const jogador of jogadores) {
      if (jogador.nome === '' || jogador.nota === 0 || jogador.idade === '') {
        alert('Preencha todas as informações dos jogadores.');
        return;
      }
    }

    const jogadoresOrdenados = [...jogadores];
    jogadoresOrdenados.sort((a, b) => {
      const comparacaoEstrelas = b.nota - a.nota;
      if (comparacaoEstrelas !== 0) {
        return comparacaoEstrelas;
      }
      return a.idade - b.idade;
    });

    const novosTimes = [];
    const jogadoresPorTime = Math.floor(quantidadeTotalJogadores / quantidadeTimes);

    for (let i = 0; i < quantidadeTimes; i++) {
      const time = {
        nome: `Time ${i + 1}`,
        jogadores: [],
      };
      novosTimes.push(time);
    }
    let currentIndex = 0;
    for (const jogador of jogadoresOrdenados) {
      const timeAtual = novosTimes[currentIndex];
      timeAtual.jogadores.push(jogador);
      currentIndex = (currentIndex + 1) % quantidadeTimes; // Circula pelos times
    }
    setTimes(novosTimes);

    
  };

  // Função para limpar o sorteio e os dados inseridos
  const limparSorteio = () => {
    setQuantidadeTotalJogadores(0);
    setQuantidadeTimes(0);
    setJogadores([]);
    setTimes([]);
  };

  return (
    <Center bgImage={`url(${img1})`} bgSize='cover' bgPosition='center' flex="1"  >
    <Box
      w={["100%", "50%"]}
 
      p={4}
      mt={4}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      mx="auto"
      backgroundColor="white"
      position="relative"
    >
      <IconButton 
       colorScheme="green" 
        icon={<IoIosArrowBack />} 
        isRound='true' 
        position='absolute' 
        top='1rem' 
        left='1rem' 
        onClick={() => window.history.back()}
      />
      <Heading size="lg" textAlign="center" mb={4}>
        Sorteio de Times - Olympique
      </Heading>
      <Flex mb={4}>
        <Box mr={4}>
          <label>Quantidade Total de Jogadores:</label>
          <Input
            type="number"
            value={quantidadeTotalJogadores}
            isReadOnly
            id="quantidadeTotalJogadores"
          />
        </Box>
        <Box>
          <label>Quantidade de Times:</label>
          <Input
            type="number"
            value={quantidadeTimes}
            onChange={(e) => setQuantidadeTimes(e.target.value)}
          />
        </Box>
      </Flex>
      {jogadores.map((jogador, index) => (
        <Flex key={index} alignItems="center" mb={2}>
          <HStack spacing={4}>
            <Box>
              <label>Nome do Jogador:</label>
              <Input
                type="text"
                value={jogador.nome}
                onChange={(e) => {
                  const novosJogadores = [...jogadores];
                  novosJogadores[index].nome = e.target.value;
                  setJogadores(novosJogadores);
                }}
              />
            </Box>
            <Box mr={4}>
              <label>Idade:   </label>
              <Input
                type="text"
                value={jogador.idade}
                onChange={(e) => {
                  const novosJogadores = [...jogadores];
                  novosJogadores[index].idade = e.target.value;
                  setJogadores(novosJogadores);
                }}
                maxLength={2}
                mt={4}
                maxWidth="53px"
              />
            </Box>
            <Box mr={2}>
              <label>Nota:</label>
              <StarRating
                value={jogador.nota}
                onChange={(value) => {
                  const novosJogadores = [...jogadores];
                  novosJogadores[index].nota = value;
                  setJogadores(novosJogadores);
                }}
              />
            </Box>
            <Button colorScheme="red" onClick={() => removerJogador(index)}>
              -
            </Button>
          </HStack>
        </Flex>
      ))}
      <Button colorScheme="blue" onClick={adicionarJogador} margin={2}>
        + Jogador
      </Button>
      <Button colorScheme="blue" onClick={realizarSorteio} margin={2}>
        Sortear 
      </Button>
      <Button colorScheme="blue" onClick={() => gerarPDFSorteio(jogadores, times)} margin={2}>
        Gerar PDF
      </Button>
      <NavLink to="/tabelaGerada">
        <Button colorScheme="blue" margin={2}>
          Tabela 
        </Button>
      </NavLink>
      <Button colorScheme="red" onClick={limparSorteio} margin={2}>
        Limpar 
      </Button>      
      <List mt={4}>
        {times.map((time, index) => (
          <ListItem key={index} p={2}>
            <Text fontWeight="bold">{time.nome}</Text>
            <List>
              {time.jogadores.map((jogador, jIndex) => (
                <ListItem key={jIndex}>
                  Nome: {jogador.nome}, Nota: {jogador.nota}, Idade: {jogador.idade}
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}
      </List>
    </Box>
    </Center>
  );
}

export default SorteioPage;
