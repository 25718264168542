import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useHistory } from 'react-router-dom'; // Importe o Link e useHistory
import AddCircleIcon from '@mui/icons-material/AddCircle';
import euro from "../assets/images/euro_2.png";
import img1 from "../assets/images/a2.jpeg";
import { http } from '../services/http';


const formatCPF = (cpfNumber) => {
  let cleanedCPF = String(cpfNumber).replace(/\D/g, '');

  if (cleanedCPF.length !== 11) {
    const zerosToAdd = 11 - cleanedCPF.length;
    const zeros = '0'.repeat(zerosToAdd);
    cleanedCPF = zeros + cleanedCPF;
  }

  return `${cleanedCPF.slice(0, 3)}.${cleanedCPF.slice(3, 6)}.${cleanedCPF.slice(6, 9)}-${cleanedCPF.slice(9)}`;
};

const OKColumn = styled('div')({
  fontSize: '12px',
  minWidth: '30px',
  maxWidth: '30px',
  textAlign: 'center',
});

const ConsultarRegistration = () => {
  const [playerList, setPlayerList] = useState([]);
 
  useEffect(() => {
    const fetchPlayerList = async () => {
      try {
        const response = await http.get('/euro/api/atletas/listarTodosTime/1');

        if (response.status === 200) {
          setPlayerList(response.data.conteudo);
        } else {
          console.error('Erro ao obter a lista de jogadores:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao processar a requisição:', error);
      }
    };

    fetchPlayerList();
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${img1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          position: 'relative',
          backgroundColor: '#ffffff',
          padding: '30px',
          maxWidth: '800px',
          borderRadius: '8px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          Lista de Jogadores Inscritos
        </Typography>

        {/* Adicione o botão para incluir jogador */}
        <Link to="/playerRegistrationForm" style={{ textDecoration: 'none' }}>
          <IconButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AddCircleIcon style={{ fontSize: '30px' }} />
            <Typography variant="caption">Incluir jogador</Typography>
          </IconButton>
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" align="center" style={{ fontWeight: 'bold', minWidth: '200px' }}>
              Nome
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" align="center" style={{ fontWeight: 'bold', minWidth: '150px' }}>
              CPF
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2" align="center" style={{ fontWeight: 'bold', minWidth: '30px' }}>
              OK
            </Typography>
          </Grid>
          {playerList.map((player) => (
            <React.Fragment key={player.id}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" align="left" noWrap>
                  {player.nomeAtleta.slice(0, 80)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" align="center">
                  {formatCPF(player.docCpf)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* Utilize o estilo personalizado para a coluna "OK" */}
                <OKColumn>{player.ativo ? 'OK' : ''}</OKColumn>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ConsultarRegistration;
