import React from 'react';
import { Box, VStack, Heading, Button, Container } from '@chakra-ui/react';
import { FaTrophy, FaUsers, FaCalendarAlt, FaCreditCard, FaCoins, FaFileInvoiceDollar } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import img1 from "../assets/images/a2.jpeg";

const AdmEuroPage = () => {
  return (
    <Box  
         bgImage={img1}
         bgPosition="center"
         bgRepeat="no-repeat"
         bgSize="cover"
         flex="1"    >
      <VStack spacing={8} align="center" justify="center" h="100%" p={10}>
        <Heading color="white">Painel Administrativo</Heading>
        <Container maxW="300px" >
          <NavLink to="/teamCreationPage">
            <Button leftIcon={<FaUsers />} w="full" colorScheme="white" variant="outline" color="green" backgroundColor="whiteAlpha.800">Times</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/playerCreationPage">
            <Button leftIcon={<FaTrophy />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Atletas</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/champsCreationPage">
            <Button leftIcon={<FaCalendarAlt />} w="full" colorScheme="white" variant="outline" color="green" backgroundColor="whiteAlpha.800">Campeonatos</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/sorteio">
            <Button leftIcon={<FaCreditCard />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Sorteio</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/financeiro">
            <Button leftIcon={<FaCoins />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Financeiro</Button>
          </NavLink>
        </Container>  
        <Container maxW="300px">
          <NavLink to="/despesas">
            <Button leftIcon={<FaFileInvoiceDollar />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Despesas</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/mesaOnlineCopaEuro">
            <Button leftIcon={<BiSupport />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Sumula</Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/suporte">
            <Button leftIcon={<BiSupport />} w="full" colorScheme="white" variant="outline"  color="green" backgroundColor="whiteAlpha.800">Suporte</Button>
          </NavLink>
        </Container>
      </VStack>
    </Box>
  );
};

export default AdmEuroPage;
