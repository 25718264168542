/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Text,
  Flex,
  Button,
  useMediaQuery,
  Select,
  Center,
} from "@chakra-ui/react";

import axios from "axios";

import { exportAsImage } from "../utils/exportAsImage";
import NoDataField from "./noDataField";
import { http } from "../services/http";

export const TabelaSemDados = () => {
  const [isLargerThan1396] = useMediaQuery("(min-width: 1396px)");
  const [isLargerThan1176] = useMediaQuery("(min-width: 1176px)");
  const [isLargerThan756] = useMediaQuery("(min-width: 756px)");

  const exportRef = useRef();

  const [timeCasa, setTimeCasa] = useState();
  const [timeVisitante, setTimeVisitante] = useState();
  const [jogadorCasa, setJogadorCasa] = useState([]);
  const [jogadorVisitante, setJogadorVisitante] = useState([]);

  const [listaTimes, setListaTimes] = useState([]);

  const dados = [
    {
      jogadors: "Jogadores",
      gols: "Gols",
      falta: "Falta",
      am: "Am",
      vm: "Vm",
    },
  ];

  // const formatarNome = (nome) => {
  //   return nome.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  // };

  const formatarNome = (nome) => {
    return nome
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getTimesCampeonato = async () => {
    const url = `/euro/api/usuario/listarTodos`;

    try {
      const response = await http.get(url);
      console.log(response);

      if (response.status === 200) {
        const times = response.data.conteudo.map((time) => ({
          id: time.id,
          nomeTime: time.nomeTime,
        }));

        setListaTimes(times);
        console.log("times: ", times);
      } else {
        console.log(`${response.status}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTimesCasa = async () => {
    const url = `/euro/api/atletas/listar-atletas-nomeTime/${timeCasa}`;

    try {
      const response = await http.get(url);

      if (response.status === 200) {
        console.log("ID TIME CASA:", response.data.conteudo[0].id);

        const urlId = `/euro/api/atletas/listar-atletas-time/${response.data.conteudo[0].id}`;
        console.log(urlId);
        try {
          const responseId = await http.get(urlId);

          if (responseId.status === 200) {
            console.log("Jogadores da casa: ", responseId.data);
            setJogadorCasa(responseId.data.conteudo);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log(`${response.status}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTimesVisitante = async () => {
    const url = `/euro/api/atletas/listar-atletas-nomeTime/${timeVisitante}`;

    try {
      const response = await http.get(url);

      if (response.status === 200) {
        console.log("ID TIME VISITANTE:", response.data.conteudo[0].id);

        const urlId = `/euro/api/atletas/listar-atletas-time/${response.data.conteudo[0].id}`;
        console.log(urlId);
        try {
          const responseId = await http.get(urlId);

          if (responseId.status === 200) {
            console.log("Jogadores visitantes: ", responseId.data);
            setJogadorVisitante(responseId.data.conteudo);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log(`${response.status}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createGame = () => {
    getTimesCasa();
    getTimesVisitante();
  };

  useEffect(() => {
    getTimesCampeonato();
  }, []);

  return (
    <Box
      flex="1"
      height="full"
      maxHeight="calc(100vh - 14rem)"
      overflowY="auto"
    >
      <Box p={6} maxW="300px">
        <Text>Time Casa</Text>
        <Select
          pb={4}
          onChange={(e) => {
            console.log(`Time casa: ${e.target.value}`);
            setTimeCasa(e.target.value);
          }}
        >
          {listaTimes.map((time, idx) => (
            <option key={idx} value={time.nomeTime}>
              {time.nomeTime}
            </option>
          ))}
        </Select>
        <Text>Time Visitante</Text>
        <Select
          pb={4}
          onChange={(e) => {
            console.log(`Time visitante: ${e.target.value}`);
            setTimeVisitante(e.target.value);
          }}
        >
          {listaTimes.map((time, idx) => (
            <option key={idx} value={time.nomeTime}>
              {time.nomeTime}
            </option>
          ))}
        </Select> 
        <Button
          colorScheme="green"
          bg="#38A169"
          color="#FFF"
          onClick={createGame}
        >
          Criar partida
        </Button>
      </Box>

      <Box ref={exportRef}>
        <Flex
          justifyContent="space-evenly"
          paddingY="1rem"
          fontSize="1.25rem"
          fontWeight="bold"
          flexDir="column"
          marginX={"auto"}
          w={isLargerThan1176 ? "100%" : "80%"}
          paddingX={isLargerThan1176 ? "12.5rem" : "auto"}
          alignItems={"flex-start"}
          gap={1}
        >
          <p>Data: </p>
          <p>Juiz: </p>
          <p>Mesário: </p>
        </Flex>
        <Flex
          flexDir={isLargerThan1176 ? "row" : "column"}
          alignItems={isLargerThan1176 ? "" : "center"}
          justifyContent="center"
        >
          <Box
            w={isLargerThan1176 ? "40%" : "80%"}
            padding={isLargerThan1176 ? "1rem" : "0.25"}
            paddingTop="2rem"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    textAlign="center"
                    border="1px solid #000"
                    fontSize="1.25rem"
                    padding={2}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gap="1rem"
                    >
                      <Text fontSize={isLargerThan1396 ? "1.5rem" : "1rem"}>
                        {timeCasa}
                      </Text>
                    </Flex>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    fontSize="1.25rem"
                    padding="0"
                  >
                    <Text fontSize={isLargerThan756 ? "1rem" : "0.75rem"}>
                      Placar:
                    </Text>
                  </Th>
                  <NoDataField />
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  {dados.map((item, index) =>
                    Object.values(item).map((value, subIndex) => (
                      <Th
                        key={`${index}-${subIndex}`}
                        maxW="4rem"
                        paddingX="0"
                        textAlign="center"
                        border="1px solid #000"
                        textTransform="capitalize"
                      >
                        {value}
                      </Th>
                    ))
                  )}
                </Tr>
              </Thead>

              <Tbody>
                {jogadorCasa.map((jogador, index) => (
                  <Tr key={jogador.id}>
                    <Td border="1px solid #000" padding="0.5rem">
                      <Flex alignItems="center" gap={3}>
                        <Input
                          type="number"
                          width="3rem"
                          p="0px"
                          textAlign="center"
                          border="1px solid black"
                          max={999}
                        />
                        <Text textTransform="capitalize">
                        {formatarNome(jogador.nomeAtleta)}
                        </Text>
                      </Flex>
                    </Td>
                    <NoDataField />
                    <NoDataField />
                    <NoDataField />
                    <NoDataField />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            w={isLargerThan1176 ? "40%" : "80%"}
            padding={isLargerThan1176 ? "1rem" : "0.25rem"}
            paddingTop="2rem"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    textAlign="center"
                    border="1px solid #000"
                    fontSize="1.25rem"
                    padding={2}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gap="1rem"
                    >
                      <Text fontSize={isLargerThan1396 ? "1.5rem" : "1rem"}>
                        {timeVisitante}
                      </Text>
                    </Flex>
                  </Th>
                  <Th
                    textAlign="center"
                    borderTop="1px solid #000"
                    borderBottom="1px solid #000"
                    fontSize="1.25rem"
                    padding="0"
                  >
                    <Text fontSize={isLargerThan756 ? "1rem" : "0.75rem"}>
                      Placar:
                    </Text>
                  </Th>
                  <NoDataField />
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  {dados.map((item, index) =>
                    Object.values(item).map((value, subIndex) => (
                      <Th
                        key={`${index}-${subIndex}`}
                        maxW="4rem"
                        paddingX="0"
                        textAlign="center"
                        border="1px solid #000"
                        textTransform="capitalize"
                      >
                        {value}
                      </Th>
                    ))
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {jogadorVisitante.map((jogador, index) => (
                  <Tr key={jogador.id}>
                    <Td border="1px solid #000" padding="0.5rem">
                      <Flex alignItems="center" gap={3}>
                        <Input
                          type="number"
                          width="3rem"
                          p="0px"
                          textAlign="center"
                          border="1px solid black"
                          max={999}
                        />
                        <Text textTransform="capitalize">
                        {formatarNome(jogador.nomeAtleta)}
                        </Text>
                      </Flex>
                    </Td>
                    <NoDataField />
                    <NoDataField />
                    <NoDataField />
                    <NoDataField />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
        <Box paddingBottom="4rem">
          <Text
            marginX="auto"
            fontWeight="bold"
            fontSize="1.25rem"
            paddingY="1rem"
            w="80%"
          >
            Observações:
          </Text>
          <Box border="1px solid #000" w="80%" marginX="auto">
            <Box h="2rem"></Box>
            <Box borderTop="1px solid #000" h="2rem"></Box>
            <Box borderTop="1px solid #000" h="2rem"></Box>
            <Box borderTop="1px solid #000" h="2rem"></Box>
            <Box borderTop="1px solid #000" h="2rem"></Box>
          </Box>

        </Box>
        
      </Box>
      
      <Center>
            <Button
           
              colorScheme="green"
              width="20%"
              bg="#38A169"
              color="#FFF"
              onClick={() => exportAsImage(exportRef.current, "sumula")}
             >
              Baixar súmula
            </Button>
          </Center>
    </Box>
  );
};
