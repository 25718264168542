import { Flex, Text } from "@chakra-ui/react";

export function Footer() {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="full"
      height="14"
      backgroundColor="#000000"
      boxShadow="lg"
    >
      <Text color="white" fontWeight="semibold" fontSize={["sm", "xs"]} textAlign="center">
        Euro &copy; , CNPJ 49.479.279/0001-27 - Todos os direitos reservados
        &bull; 2023
      </Text>
    </Flex>
  );
}
