/* eslint-disable no-undef */
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  Heading,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

import { Image } from "@chakra-ui/react";
import * as yup from "yup";

import { Formik, ErrorMessage } from "formik";

import { useNavigate, useParams } from "react-router-dom";

import afpuma from "../assets/images/afpuma.jpeg";
import ajax from "../assets/images/ajax.jpeg";
import amarelao from "../assets/images/amarelao.jpeg";
import arsenal from "../assets/images/arsenal.jpeg";
import furia from "../assets/images/furia.jpeg";
import galaticos from "../assets/images/galaticos.jpeg";
import arrebite from "../assets/images/arrebite.jpeg";
import mb from "../assets/images/mb.jpeg";
import olympique from "../assets/images/olympique.jpeg";
import sparta from "../assets/images/sparta.jpeg";

import axios from "axios";
import { useForm } from "react-hook-form";
import { http } from "../services/http";

const validationResponsible = yup.object().shape({
  name: yup.string().required("Campo obrigatório!"),
  email: yup.string().required("Campo obrigatório!"),
  rg: yup.string().required("Campo obrigatório!"),
  telefone: yup.string().required("Campo obrigatório!"),
});

const ResponsiblePlayer = {
  timesId: {
    login: "",
    password: "",
  },
  nomeTime: "",
  nomeResp: "",
  telefone: "",
  email: "",
  rg: 0,
  inResp: "",
};

// const initialValues = {
//   name: "",
//   email: "",
//   rg: "",
//   telefone: "",
// };

function ResponsibleData() {
  const { login } = useParams();

  const [nomeResp, setNomeResp] = useState("");
  const [email, setEmail] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");

  const [logo, setLogo] = useState("");

  const [menor920] = useMediaQuery("(max-width: 920px)");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log(login);

  const addPost = (data) => {
    const infoTime = JSON.parse(localStorage.getItem("infoTime"));

    const rg = data.rg.length <= 9 ? data.rg : data.rg.slice(0, 9);

    http
      .put(`/euro/api/times/altera`, { // Alterando a chamada HTTP para utilizar o objeto 'http'
        timesId: {
          login: infoTime.timesId.login,
          password: infoTime.timesId.password,
        },
        nomeTime: infoTime.nomeTime,
        nomeResp: data.nomeResp,
        telefone: data.telefone,
        email: data.email,
        rg: data.rg.length <= 9 ? data.rg : data.rg.slice(0, 8),
        inResp: "S",
      })
      .then((response) => {
        //alert("Deu tudo certo  ");
        navigate(`/player`);
      })
      .catch((err) => {
        // alert("DEU ERRADO");
        console.log(err);
      });
  };

  useEffect(() => {
    switch (login) {
      case "user.Afpuma":
        setLogo(afpuma);
        break;

      case "user.Ajax":
        setLogo(ajax);
        break;

      case "user.Amarelao":
        setLogo(amarelao);
        break;

      case "user.Arsenal":
        setLogo(arsenal);
        break;

      case "user.Furia":
        setLogo(furia);
        break;

      case "user.Galaticos":
        setLogo(galaticos);
        break;

      case "user.Arrebite":
        setLogo(arrebite);
        break;

      case "user.MeiaBoca":
        setLogo(mb);
        break;

      case "user.Olympique":
        setLogo(olympique);
        break;

      case "user.Sparta":
        setLogo(sparta);
        break;

      default:
        setLogo("");
    }
  }, []);

  return (
    // <Formik
    //   onSubmit={handleSubmit}
    //   initialValues={initialValues}
    //   validationSchema={validationResponsible}
    //   validateOnBlur={false}
    //   validateOnChange={false}
    //   validateOnMount={false}
    // >
    //   {({
    //     errors,
    //     values,
    //     handleChange,
    //     handleBlur,
    //     handleSubmit,
    //     touched,
    //   }) => (
    <form>
      <Box h="100vh">
        <Center
          as="header"
          h={150}
          bg="green.600"
          color="white"
          fontWeight="bold"
          fontSize={["2xl", "3xl", "4xl"]}
          pb="8"
        >
          Formulário de Inscrição
        </Center>

        <Flex
          align="center"
          justify="center"
          bg="blackAlpha.200"
          h="calc(100vh - 150px)"
        >
          <Center
            w="100%"
            maxW={840}
            bg="white"
            top={100}
            position="absolute"
            borderRadius={5}
            p="6"
            boxShadow="0 1px 2px #ccc"
          >
            <FormControl display="flex" flexDir="column" gap="4">
              <VStack>
                {" "}
                <Image
                  src={logo}
                  w={menor920 ? "15%" : "9%"}
                  borderRadius="5%"
                />
              </VStack>
              <VStack>
                <Heading
                  fontSize={["2xl", "3xl", "4xl"]}
                  fontWeight="bold"
                  textAlign="center"
                  color="green.600"
                  marginBottom="3%"
                >
                  Dados do responsável
                </Heading>

                <FormLabel paddingBottom={3}>Nome Completo</FormLabel>
                <Input
                  w={menor920 ? "90%" : "50%"}
                  type="text"
                  name="nameResp"
                  {...register("nomeResp")}
                ></Input>

                <FormLabel paddingBottom={3}>RG</FormLabel>
                <Input
                  placeholder="Somente números"
                  type="text"
                  w={menor920 ? "90%" : "50%"}
                  name="rg"
                  {...register("rg")}
                  onKeyPress={(e) => {
                    const keyCode = e.keyCode || e.which; // Verifica o código da tecla pressionada
                    const keyValue = String.fromCharCode(keyCode); // Converte o código da tecla para um caractere

                    if (/[^0-9]/.test(keyValue)) {
                      // Verifica se o caractere digitado não é um número
                      e.preventDefault(); // Cancela o evento para impedir que o caractere seja exibido no campo
                    }
                  }}
                ></Input>

                <FormLabel paddingBottom={3}>Telefone</FormLabel>
                <Input
                  w={menor920 ? "90%" : "50%"}
                  name="telefone"
                  type="text"
                  {...register("telefone")}
                ></Input>

                <FormLabel paddingBottom={3}>Email</FormLabel>
                <Input
                  w={menor920 ? "90%" : "50%"}
                  name="email"
                  type="email"
                  {...register("email")}
                ></Input>

                <Box>
                  {" "}
                  <Button
                    w={150}
                    p="6"
                    onClick={handleSubmit(addPost)}
                    bg="green.600"
                    color="white"
                    fontWeight="bold"
                    fontSize="xl"
                    mt="2"
                    _hover={{ bg: "teal.800" }}
                  >
                    Enviar
                  </Button>
                </Box>
              </VStack>
            </FormControl>
          </Center>
        </Flex>
      </Box>
    </form>
    //   )}
    // </Formik>
  );
}

export default ResponsibleData;
