import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Select,
  Box,
  Flex,
  VStack
} from "@chakra-ui/react";
import { http } from '../services/http';

const AthletesListModal = ({ isOpen, onClose }) => {
  const [athletes, setAthletes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const loadTeams = async () => {
    try {
      const response = await http.get('/euro/api/times/listarTodos');
      const teams = response.data.conteudo.map((team) => ({ id: team.idTime, nome: team.nomeTime }));
      setTeams(teams);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const loadAthletes = async (teamId) => {
    try {
      const response = await http.get(`/euro/api/atletas/listar-atletas-time/${teamId}`);
      setAthletes(response.data.conteudo);
      console.log(response.data)
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  useEffect(() => {
    if (isOpen) {
      loadTeams();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedTeam) {
      loadAthletes(selectedTeam);
    }
  }, [selectedTeam]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Lista de Atletas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select placeholder="Selecione o time" onChange={(e) => setSelectedTeam(e.target.value)}>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.nome}
              </option>
            ))}
          </Select>
          {athletes.map((athlete) => (
            <Box key={athlete.id} borderWidth="1px" borderRadius="lg" overflow="hidden" my={2} p={2}>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm"><strong>Nome:</strong> {athlete.nomeAtleta}</Text>
                <Text fontSize="sm"><strong>CPF:</strong> {athlete.documento}</Text>
                <Text fontSize="sm"><strong>Data de Nascimento:</strong> {athlete.dataNasc || '-'}</Text>
              </VStack>
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AthletesListModal;
