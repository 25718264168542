import { RiHome3Line } from "react-icons/ri";
import { EPaths } from "../navs/paths";
import { LuCalendarDays } from "react-icons/lu";
import { IoIosFootball } from "react-icons/io";
import { MdBlock } from "react-icons/md";
import { GoDownload } from "react-icons/go";
import { VscGraph } from "react-icons/vsc";
import { TbCards } from "react-icons/tb";

export const AuthenticatedNavMock = [
  {
    label: "Início",
    path: EPaths.LOGIN,
    icon: RiHome3Line,
  }, 
   {
     label: "Classificação",
     path: EPaths.CLASSIFICATIONS,
     icon: VscGraph,
   },
  {
    label: "Tabela",
    path: EPaths.TABLES,
    icon: LuCalendarDays,
  },
   {
     label: "Artilharia",
     path: EPaths.ARTILLERY,
     icon: IoIosFootball,
   },
   {
     label: "Cartões",
     path: EPaths.DISCIPLINE,
     icon: TbCards,
   },
  // {
  //   label: "Download",
  //   icon: GoDownload,
  //   onClick: () => {
  //     window.open("https://green-frost-5892.fly.dev/download", "_blank");
  //   },
  // },
];


