import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  VStack,
  Flex,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

import { http } from "../services/http";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menor1400] = useMediaQuery("(max-width: 1400px)");
  const [exibirCopaEuro, setExibirCopaEuro] = useState(false);
  const [isOnVisible, setIsOnVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const login = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/euro/api/usuario/${email}/${password}`);

      if (response.status === 200) {
        const data = response.data;

        if (data.conteudo != null && data.conteudo.nomeTime === "adm") {
          navigate("/admEuro");
        } else {
          console.log("Id:", data.conteudo.id);
          alert(
           "Inscrições encerradas."
          );
          // navigate("/playerRegistrationForm", {
          //   state: { id: data.conteudo.id, usuLogin: data.conteudo.usuLogin },
          // });
        }
      } else {
        alert(
          "Erro ao efetuar Login. Verifique sua conexão ou tente novamente mais tarde."
        );
      }
    } catch (error) {
      console.error("Ocorreu um erro na chamada da API:", error);
      alert(
        "Erro ao efetuar Login. Verifique sua conexão ou tente novamente mais tarde."
      );
    }

    setIsLoading(false);
  };

  const toggleExibirCopaEuro = () => {
    setExibirCopaEuro(!exibirCopaEuro);
  };

  return (
    <Box
      flex="1"
      bg="#efefef"
    >
      <Flex direction="column" align="center" justify="center" h="50vh">
        <VStack
          spacing={4}
          p={6}
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          w={menor1400 ? "90%" : "22%"}
        >
          <Text fontSize="xl" fontWeight="bold">
            Inscrição de Atletas
          </Text>
          <FormControl id="email">
            <FormLabel>Usuário</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Senha</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            colorScheme="green"
            //colorScheme="black"
            size="lg"
            w="100%"
            onClick={login}
            isLoading={isLoading}
          >
            Entrar
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
};

export default LoginPage;
