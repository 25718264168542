/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  HStack,
  Heading,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useMediaQuery,
  VStack,
  Checkbox,
  Input,
  Link,
} from "@chakra-ui/react";
import axios from "axios";

import { useEffect, useState } from "react";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { http } from "../services/http";

function ConsultaPagamento() {
  const [menor920] = useMediaQuery("(max-width: 920px)");
  const [menor704] = useMediaQuery("(max-width: 704px)");

  const [players, setPlayers] = useState([]);
  const [times, setTimes] = useState([]);
  const [time, setTime] = useState("");
  const [jogador, setJogador] = useState();
  const [timeSelecionado, setTimeSelecionado] = useState();
  const [metodoDePagamento, setMetodoDePagamento] = useState("");
  const [pagamento, setPagamento] = useState([]);
  const [valorPagamento, setValorPagamento] = useState();

  const valorTotal = 285;

  useEffect(() => {
    getTime();
    //  getPagamento();
  }, []);

  const getPlay = async (time) => {
    try {
      const { data } = await http.get(
        `/euro/api/equipes/${time.timesId.login}/0`
      ); // Utilize a instância 'http' que você criou
      //const URL = `http://localhost:8083/api/equipes/${time.timesId.login}/0`;

      setPlayers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTime = async () => {
    try {
      const { data } = await http.get("/euro/api/times/listarTodos"); // Utilize a instância 'http' que você criou
      //const URL = `http://localhost:8083/api/times/listarTodos`;

      setTimes(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPagamento = async (time) => {
    try {
      const { data } = await http.get(
        `/euro/api/pgto/lista/${time.timesId.login}`
      ); // Utilize a instância 'http' que você criou
      //const URL = `https://euro-solucoes-esportivas-1b5630df6a01.herokuapp.com/euro/api/times/listarTodos`;
      //const URL = `http://localhost:8083/api/pgto/lista/${time.timesId.login}`;
      setPagamento(data);
    } catch (error) {
      console.log(error);
    }
  };

  /*async function getPagamento(time) {

    //const response = await axios.post(`https://euro-solucoes-esportivas-1b5630df6a01.herokuapp.com/euro/api/pgto/lista/${time.timesId.login}`, requestBody);
    const response = await axios.get(`http://localhost:8083/api/pgto/lista/${time.timesId.login}`);
    try {
      setPagamento(response.data);

      console.log('aqui')
      console.log("get", response.data)
    } catch (error) {
      console.log(error);
    }
  };*/

  return (
    <Box bg="blackAlpha.200" h="100vh">
      <Center
        as="header"
        h={150}
        bg="green.600"
        color="white"
        fontWeight="bold"
        fontSize="4xl"
        pb="8"
      ></Center>

      <Flex flexDirection="column" alignItems="center">
        <Box
          marginTop="-3%"
          height="min-content"
          display="block"
          w={menor920 ? "80%" : "100%"}
          maxW={840}
          bg="white"
          borderRadius={5}
          p="6"
          boxShadow="0 1px 2px #ccc"
        >
          <form style={{ width: "100%" }}>
            <FormControl display="flex" flexDir="column" gap="4">
              <VStack></VStack>

              <Heading
                fontSize={["xl", "2xl", "3xl"]}
                textAlign="center"
                color="#38a169"
              >
                Pagamentos
              </Heading>

              <HStack
                alignItems="center"
                flexDirection={menor704 ? "column" : "row"}
                justifyContent="space-around"
              ></HStack>

              <Box>
                <Text
                  textTransform="capitalize"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  paddingBottom="1rem"
                >
                  Selecionar time
                </Text>
                {/* <Select
                  textTransform="capitalize"
                  type="text"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value="Fúria">Fúria</option>
                  <option value="Meia Boca">Meia Boca</option>
                  <option value="Ajax">Ajax</option>
                  <option value="Galaticos">Galaticos</option>
                  <option value="Arsenal">Arsenal</option>
                </Select> */}

                <Select
                  type="text"
                  value={timeSelecionado ? timeSelecionado.nomeTime : ""}
                  onChange={(e) => {
                    const time = times.filter(
                      (time) => time.nomeTime == e.target.value
                    )[0];
                    setTimeSelecionado(time);
                    setJogador();
                    getPlay(time);
                    console.log(time);
                    getPagamento(time);
                  }}
                >
                  {times.map((p) => (
                    <option textAlign="center" value={p.nomeTime}>
                      {p.nomeTime}
                    </option>
                  ))}
                </Select>
              </Box>
            </FormControl>
          </form>
          <VStack marginTop={6}>
            <Link href="/administrador">Voltar</Link>
          </VStack>
        </Box>
      </Flex>

      <Box margin="0 auto" w={menor920 ? "80%" : "100%"} maxW={840}>
        <Flex
          flexDirection={menor704 ? "column" : "row"}
          w={menor920 ? "100%" : "100%"}
          maxW={840}
          marginTop="3%"
          padding={3}
          bg="#2e855a"
          alignItems="center"
          justifyContent="space-around"
        >
          {" "}
          <Text
            color="white"
            fontSize={["2xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Pagamentos
          </Text>
        </Flex>

        <TableContainer bg="white" w="100%" maxW={840}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textAlign="center">Nome</Th>
                <Th textAlign="center">RG</Th>
                <Th textAlign="center">Pago</Th>
                <Th textAlign="center">A pagar</Th>
                <Th textAlign="center">Total</Th>
              </Tr>
            </Thead>

            <Tbody>
              {pagamento.map((p, index) => (
                <Tr key={index}>
                  <Td textAlign="center">{p.nomeJogadorPgto}</Td>
                  <Td textAlign="center">{p.pgtoId.rg}</Td>
                  <Td textAlign="center">{p.valorPgto},00</Td>
                  <Td textAlign="center">{valorTotal - p.valorPgto}</Td>
                  <Td textAlign="center">{valorTotal},00</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ConsultaPagamento;
