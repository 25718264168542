import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  IconButton,
  Select,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { http } from "../services/http";
import { LazyImage } from '../Components/lazyImage';

function TabelaJogos() {
  const [rodadaSelecionada, setRodadaSelecionada] = useState(1);
  const [quantidadeRodadas, setQuantidadeRodadas] = useState();
  const [jogosRodada, setJogosRodada] = useState([]);

  useEffect(() => {
    const fetchQuantidadeRodadas = async () => {
      try {
        const response = await http.get(`/euro/api/jogos/listar-rodada/1`);
        const data = response.data;
        setQuantidadeRodadas(data.conteudo.length);
      } catch (error) {
        console.error("Erro ao buscar a quantidade de rodadas:", error);
      }
    };

    fetchQuantidadeRodadas();
  }, []);

  useEffect(() => {
    const fetchJogosRodada = async () => {
      try {
        const response = await http.get(
          `/euro/api/jogos/listar-rodada/${rodadaSelecionada}`
        );  
        //  `http://localhost:8080/euro/api/jogos/listar-rodada/${rodadaSelecionada}`

        const data = response.data;
        setJogosRodada(data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar os jogos da rodada:", error);
      }
    };

    fetchJogosRodada();
  }, [rodadaSelecionada]);

  const proximaRodada = () => {
    setRodadaSelecionada(prev => Math.min(prev + 1, 12));
  };

  const rodadaAnterior = () => {
    setRodadaSelecionada(prev => Math.max(prev - 1, 1));
  };

  const handleSelectChange = (e) => {
    setRodadaSelecionada(parseInt(e.target.value));
  };

  return (
    <ChakraProvider>
      <Flex
        flex="1"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={4}
      >
        <Box
          width={{ base: "90%", md: "80%", lg: "40%" }}
          maxHeight="calc(100vh - 14rem)"
          overflowY="auto"
        >
          <Flex justify="space-between" align="center" mb={4}>
            <IconButton
              aria-label="Anterior"
              icon={<ChevronLeftIcon />}
              onClick={rodadaAnterior}
              variant="ghost"
              colorScheme="green"
              size="lg"
            />
            <Select
              textAlign="center"
              value={rodadaSelecionada}
              onChange={handleSelectChange}
              fontWeight="bold"
            >
              {Array.from({ length: 12 }, (_, index) => {
                if (index < 9) {
                  return (
                    <option key={index + 1} value={index + 1}>
                      Rodada {index + 1}
                    </option>
                  );
                } else if (index === 9) {
                  return <option key={index + 1} value={index + 1}>Quartas-de-Final</option>;
                } else if (index === 10) {
                  return <option key={index + 1} value={index + 1}>Semi-final</option>;
                } else if (index === 11) {
                  return <option key={index + 1} value={index + 1}>Final</option>;
                }
              })}
            </Select>


            <IconButton
              aria-label="Próxima"
              icon={<ChevronRightIcon />}
              onClick={proximaRodada}
              variant="ghost"
              colorScheme="green"
              size="lg"
            />
          </Flex>
          {jogosRodada.map((jogo, index) => (
            <Box key={index} bg="white" p={7} mb={4} borderRadius="md" boxShadow="md" textAlign="center">
              <Heading size={["md"]} mb="5">
                {jogo.dataJogo} - {jogo.horaJogo}hs
              </Heading>
              <Flex align="center" justifyContent="center">
                {jogo.timeA?.imagem ? (
                  <Avatar
                    src={`data:image/jpeg;base64,${jogo.timeA.imagem}`}
                    alt={jogo.timeA.nomeTime}
                    name={jogo.timeA.nomeTime}
                    size={{ base: "md", md: "lg" }} // Ajuste do tamanho do avatar
                    mr={4}
                  />
                ) : (
                  <Avatar
                    name={jogo.timeA ? jogo.timeA.nomeTime : ' '}
                    //name={jogo.timeA ? jogo.timeA.nomeTime : 'Time A'}
                    size={{ base: "md", md: "lg" }} // Ajuste do tamanho para o avatar padrão
                    mr={4}
                    bg="gray.200" // Background do avatar padrão
                  />
                )}
                <Heading size={["xl", "2xl"]} mx={2}>
                  {jogo.golsTimeA} x {jogo.golsTimeB}
                </Heading>
                {jogo.timeB?.imagem ? (
                  <Avatar
                    src={`data:image/jpeg;base64,${jogo.timeB.imagem}`}
                    alt={jogo.timeB.nomeTime}
                    name={jogo.timeB.nomeTime}
                    size={{ base: "md", md: "lg" }} // Ajuste do tamanho do avatar
                    ml={4}
                  />
                ) : (
                  <Avatar
                    //name={jogo.timeB ? jogo.timeB.nomeTime : 'Time B'}
                    name={jogo.timeB ? jogo.timeB.nomeTime : ' '}
                    size={{ base: "md", md: "lg" }} // Ajuste do tamanho para o avatar padrão
                    ml={4}
                    bg="gray.200" // Background do avatar padrão
                  />
                )}
              </Flex>
            </Box>
          ))}

        </Box>
      </Flex>
    </ChakraProvider>
  );
}

export default TabelaJogos;

