import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PagesRoutes } from './routes/index';

const theme = extendTheme({
  colors: {
    primary: "#6200EA",
    secondary: "#F50057",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <PagesRoutes />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
