import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useMediaQuery,
  Text,
  Flex,
  Select,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { IconButton } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import img2 from "../assets/images/img4.png";
import { http } from "../services/http";

const PlayerUpdatePage = () => {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [athletesByTeam, setAthletesByTeam] = useState([]); // Estado para armazenar os atletas do time selecionado
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    nomeCompleto: Yup.string().required("Nome do atleta é obrigatório"),
    cpf: Yup.string().required("CPF é obrigatório"),
    dataNascimento: Yup.string().required("Data de nascimento é obrigatória"),
    posicao: Yup.string().required("Posição do atleta é obrigatória"),
    time: Yup.string().required("Time é obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      time: "",
      posicao: "",
      atleta: "", // Adicione este campo
    },
  });

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await http.get("/euro/api/times/listarTodos");
        const teams = response.data.conteudo.map((team) => ({
          id: team.idTime,
          nome: team.nomeTime,
        }));
        setTeamOptions(teams);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchTeams();
  }, []);

  const handleSelectTeam = (event) => {
    const teamId = event.target.value; // Agora, teamId é o ID do time selecionado
    setSelectedTeam(teamId);
    setSelectedAthlete(null);
    listAthletesByTeam(teamId); // Passe o ID do time selecionado aqui
  };

  const listAthletesByTeam = async (teamId) => {
    try {
      const response = await http.get(
        `/euro/api/atletas/listar-atletas-time/${teamId}`
      );
      setAthletesByTeam(response.data.conteudo);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleSelectAthlete = async (athleteId) => {
    try {
      const response = await http.get(`/euro/api/atletas/${athleteId}`);
      setSelectedAthlete(response.data.conteudo);
      reset({
        nomeCompleto: response.data.conteudo.nomeAtleta,
        cpf: response.data.conteudo.documento,
        dataNascimento: response.data.conteudo.dataNasc,
        posicao: response.data.conteudo.posicaoAtleta,
        time: selectedTeam,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      const resizedImage = await resizeImage(imageFile, 400, 280);
      setSelectedFile(resizedImage);
      setIsFilePicked(true);
    }
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              })
            );
          },
          "image/jpeg",
          1
        );
      };
      image.onerror = (error) => {
        reject(new Error("Could not load image"));
      };
    });
  };

  const handleSubmission = async (data) => {
    try {
      const imageBase64 = await toBase64(selectedFile);
      await http.put(`/euro/api/atletas/altera`, {
        id: selectedAthlete.id,
        idTimes: data.novoTime,
        nomeAtleta: data.nomeCompleto,
        documento: data.cpf,
        dataNasc: data.dataNascimento,
        posicaoAtleta: data.posicao,
        imagem: imageBase64,
      });

      toast({
        title: "Atualização bem-sucedida.",
        description: "Informações do atleta atualizadas com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setSelectedAthlete(null);
      reset();
      setIsFilePicked(false);
      navigate(-1);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const cpfMask = "999.999.999-99";
  const dateMask = "99/99/9999";

  return (
    <Box
      backgroundImage={img2}
      backgroundSize="cover"
      backgroundPosition="center"
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w={isLargerThan480 ? "40%" : "80%"}
        p={4}
        mt={4}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        bg="whiteAlpha.900"
        position="relative"
      >
        <IconButton
          colorScheme="green"
          aria-label="Voltar"
          icon={<IoIosArrowBack />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={2}
          left={2}
        />

        <Text fontSize="xl" textAlign="center" mb={4}>
          Painel de Atletas
        </Text>

        <form onSubmit={handleSubmit(handleSubmission)}>
          <Flex mt={4}>
            <FormControl id="time" flex="1" margin={1} isInvalid={errors.time}>
              <FormLabel>Time</FormLabel>
              <Select
                size="sm"
                {...register("time")}
                errorBorderColor="red.300"
                onChange={handleSelectTeam}
              >
                <option value="">Selecione um time</option>
                {teamOptions.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.nome}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="atleta"
              flex="1"
              margin={1}
              isInvalid={errors.atleta}
            >
              <FormLabel>Atleta</FormLabel>
              <Select
                size="sm"
                {...register("atleta")}
                errorBorderColor="red.300"
                onChange={(e) => handleSelectAthlete(e.target.value)}
              >
                <option value="" style={{ color: "black" }}>
                  Selecione um atleta
                </option>
                {selectedTeam &&
                  athletesByTeam.map((athlete) => (
                    <option
                      key={athlete.id}
                      value={athlete.id}
                      style={{ color: "black" }}
                    >
                      {athlete.nomeAtleta}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>{errors.atleta?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl id="nomeCompleto" isInvalid={errors.nomeCompleto}>
            <FormLabel>Nome Completo</FormLabel>
            <Input
              size="sm"
              type="text"
              {...register("nomeCompleto")}
              errorBorderColor="red.300"
            />
            <FormErrorMessage>{errors.nomeCompleto?.message}</FormErrorMessage>
          </FormControl>

          <Flex mt={4}>
            <FormControl id="cpf" flex="1" isInvalid={errors.cpf}>
              <FormLabel>CPF</FormLabel>
              <InputMask
                mask={cpfMask}
                value={watch("cpf")}
                onChange={(e) => setValue("cpf", e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    size="sm"
                    type="text"
                    errorBorderColor="red.300"
                  />
                )}
              </InputMask>
              <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="dataNascimento"
              flex="1"
              ml={4}
              isInvalid={errors.dataNascimento}
            >
              <FormLabel>Data de Nasc</FormLabel>
              <InputMask
                mask={dateMask}
                value={watch("dataNascimento")}
                onChange={(e) => setValue("dataNascimento", e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    size="sm"
                    type="text"
                    errorBorderColor="red.300"
                  />
                )}
              </InputMask>
              <FormErrorMessage>
                {errors.dataNascimento?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex>
            <FormControl
              id="posicao"
              mt={4}
              margin={1}
              isInvalid={errors.posicao}
            >
              <FormLabel>Posição</FormLabel>
              <Input
                size="sm"
                type="text"
                {...register("posicao")}
                errorBorderColor="red.300"
              />
              <FormErrorMessage>{errors.posicao?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="novoTime"
              mt={4}
              margin={1}
              isInvalid={errors.novoTime}
            >
              <FormLabel>Novo Time</FormLabel>
              <Select
                size="sm"
                {...register("novoTime")}
                errorBorderColor="red.300"
              >
                <option value="">Transferencia de time</option>
                {teamOptions.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.nome}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.novoTime?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl id="file" mt={4}>
            <FormLabel>Upload de Imagem</FormLabel>
            <Input size="sm" type="file" onChange={handleImageChange} />
            <FormErrorMessage>{errors.file?.message}</FormErrorMessage>
          </FormControl>

          {isFilePicked ? (
            <div>
              <p>Filename: {selectedFile ? selectedFile.name : ""}</p>
              <p>Filetype: {selectedFile ? selectedFile.type : ""}</p>
              <p>Size in bytes: {selectedFile ? selectedFile.size : ""}</p>
            </div>
          ) : (
            <p>Select a file to show details</p>
          )}

          <VStack spacing={4} mt={4} align="stretch">
            <Button colorScheme="green" type="submit">
              Enviar
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

function toBase64(file) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("Input is not a valid Blob."));
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default PlayerUpdatePage;
