import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Image,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import axios from "axios";
import { http } from "../services/http";

const ListarAtletasModal = ({ isOpen, onClose, championshipId }) => {
  const [teamId, setTeamId] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);
  const [athleteData, setAthleteData] = useState(null);

  const fetchTeamOptions = async () => {
    try {
      const response = await http.get(
        `/euro/api/inscricoes/campeonato/${championshipId}`
      );
      const teamPromises = response.data.conteudo.map(async (team) => {
        const teamResponse = await http.get(
          `/euro/api/usuario/${team.inscricaoId.idTimes}`
        );
        return {
          id: team.inscricaoId.idTimes,
          nome: teamResponse.data.conteudo.nomeTime,
        };
      });
      const teams = await Promise.all(teamPromises);
      setTeamOptions(teams);
    } catch (error) {
      console.error("There was an error fetching team options!", error);
      setTeamOptions([]);
    }
  };

  const fetchAthleteData = async (teamId) => {
    try {
      console.log("A:", championshipId, "B:", teamId);
      const response = await http.get(
        `/euro/api/sumula/${championshipId}/${teamId}`
      );

      const athletePromises = response.data.conteudo.map(async (athlete) => {
        const athleteResponse = await http.get(
          `/euro/api/atletas/${athlete.sumulaId.idAtletas}`
        );

        // Combinando as propriedades dos dois objetos
        return { ...athlete, ...athleteResponse.data.conteudo };
      });

      const athletes = await Promise.all(athletePromises);
      setAthleteData(athletes);
    } catch (error) {
      console.error("There was an error fetching athlete data!", error);
      setAthleteData([]);
    }
  };

  useEffect(() => {
    if (isOpen && championshipId) {
      fetchTeamOptions();
    }
  }, [isOpen, championshipId]);

  useEffect(() => {
    if (teamId) {
      fetchAthleteData(teamId);
    }
  }, [teamId]);

  const handleClose = () => {
    setAthleteData(null);
    setTeamId(null);
    onClose();
  };

  const handleTeamChange = (event) => {
    setTeamId(event.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dados do Atleta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {teamOptions.length > 0 && (
            <FormControl id="time" marginBottom={2}>
              <FormLabel>Selecione um Time</FormLabel>
              <Select
                placeholder="Selecione um time"
                onChange={handleTeamChange}
                borderRadius="md"
              >
                {teamOptions.map((team) => (
                  <option key={team.id} value={team.id}>
                    {team.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {athleteData &&
            athleteData.map(
              (athlete) =>
                athlete && (
                  <Box
                    key={athlete.idAtleta}
                    padding={5}
                    borderWidth={1}
                    borderRadius="md"
                    marginBottom={3}
                  >
                    <Text fontWeight="bold" marginBottom={1}>
                      {athlete.nomeAtleta || "Sem nome"}
                    </Text>
                    <Text>Data de Nascimento: {athlete.dataNasc}</Text>
                    <Text>Apto: {athlete.aptoJogo}</Text>
                  </Box>
                )
            )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ListarAtletasModal;
