import React from "react";
import { Box, VStack, Heading, Button, Container } from "@chakra-ui/react";
import { FaTrophy, FaListUl, FaChartLine, FaBan } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { NavLink } from "react-router-dom";

const PainelEuro = () => {
  return (
    <Box
      h="100vh"
      bgColor="#e2e8f0"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <VStack spacing={8} align="center" justify="center" h="100%" p={10}>
        <Heading color="green">Copa Euro 30 +</Heading>
        <Container maxW="300px">
          <NavLink to="/classificacoes">
            <Button
              leftIcon={<FaListUl />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Classificação
            </Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/tabelaJogo">
            <Button
              leftIcon={<FaTrophy />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Tabelas
            </Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/artilharia">
            <Button
              leftIcon={<FaChartLine />}
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Artilharia
            </Button>
          </NavLink>
        </Container>
        <Container maxW="300px">
          <NavLink to="/suspensao">
            <Button
              leftIcon={<FaBan />}
              s
              w="full"
              colorScheme="white"
              variant="outline"
              color="green"
              backgroundColor="whiteAlpha.800"
            >
              Suspensão
            </Button>
          </NavLink>
        </Container>
      </VStack>
    </Box>
  );
};

export default PainelEuro;
