import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  ModalFooter,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { http } from '../services/http';

const UserModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    usuLogin: Yup.string().required('Usuário é obrigatório'),
    senha: Yup.string().required('Senha é obrigatória'),
    nomeTime: Yup.string().required('Time é obrigatório'),
    documento: Yup.string().nullable(),
    txEmailResp: Yup.string().email('Digite um email válido').nullable(),
    telResp: Yup.string().nullable(),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

       if (!data.txEmailResp) delete data.txEmailResp;
      if (!data.documento) delete data.documento;
      if (!data.telResp) delete data.telResp;

      console.log("requisição:", data)
      const response = await http.post('/euro/api/usuario', data);  

      if (response.status === 200) {
        toast({
          title: 'Inclusão bem-sucedida.',
          description: 'Usuário inserido com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        console.error('Erro ao enviar o formulário:', response.statusText);
        toast({
          title: 'Erro na inclusão.',
          description: 'Ocorreu um erro ao enviar o formulário.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      toast({
        title: 'Erro na inclusão.',
        description: 'Ocorreu um erro ao enviar o formulário.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Inclusão de Usuário</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Usuário</FormLabel>
              <Input
                name="usuLogin"
                placeholder="Digite o nome de usuário"
                {...register("usuLogin")}
              />
              {errors.usuLogin && <span style={{ color: 'red' }}>{errors.usuLogin.message}</span>}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Senha</FormLabel>
              <Input
                name="senha"
                type="password"
                placeholder="Digite a senha"
                {...register("senha")}
              />
              {errors.senha && <span style={{ color: 'red' }}>{errors.senha.message}</span>}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Time</FormLabel>
              <Input
                name="nomeTime"
                placeholder="Digite o nome do time"
                {...register("nomeTime")}
              />
              {errors.nomeTime && <span style={{ color: 'red' }}>{errors.nomeTime.message}</span>}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Documento</FormLabel>
              <Input
                name="documento"
                placeholder="Digite o documento"
                {...register("documento")}
              />
              {errors.documento && <span style={{ color: 'red' }}>{errors.documento.message}</span>}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Email do Responsável</FormLabel>
              <Input
                name="txEmailResp"
                type="email"
                placeholder="Digite o email do responsável"
                {...register("txEmailResp")}
              />
              {errors.txEmailResp && <span style={{ color: 'red' }}>{errors.txEmailResp.message}</span>}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Telefone do Responsável</FormLabel>
              <Input
                name="telResp"
                type="text"
                placeholder="Digite o telefone do responsável"
                {...register("telResp")}
              />
              {errors.telResp && <span style={{ color: 'red' }}>{errors.telResp.message}</span>}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="green" isLoading={isLoading}>
              Enviar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;
