import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useMediaQuery,
  useToast,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import img2 from "../assets/images/a2.jpeg";
import UserModal from "../Components/userModal";
import InputMask from "react-input-mask";
import { http } from "../services/http";

const TeamCreationPage = () => {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const cancelRef = useRef();

  const clearFormFields = () => {
    setValue("nomeTime", "");
    setValue("documento", "");
    setSelectedFile(null);
    setIsFilePicked(false);
  };

  const handleOpenUserModal = () => {
    setIsUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    nomeTime: Yup.string().required("Nome do time é obrigatório"),
    documento: Yup.string()
      .required("Documento é obrigatório")
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Documento inválido"),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const formatDocumento = (value) => {
    if (!value) return value;

    const numericValue = value.replace(/\D/g, "");

    let maskedValue = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 3 || i === 6) {
        maskedValue += `.${numericValue.charAt(i)}`;
      } else if (i === 9) {
        maskedValue += `-${numericValue.charAt(i)}`;
      } else {
        maskedValue += numericValue.charAt(i);
      }
    }

    return maskedValue;
  };

  const handleDocumentoChange = (event) => {
    const { value } = event.target;
    const maskedValue = formatDocumento(value);
    setValue("documento", maskedValue, { shouldValidate: true });
  };

  const handleSubmission = async (data) => {
    try {
      setIsLoading(true);

      if (isFilePicked) {
        const reader = new FileReader();
        reader.onloadend = () => {
          data.imagem = reader.result.split(",")[1];
          submitForm(data);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        submitForm(data);
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      toast({
        title: "Erro na inclusão.",
        description: "Ocorreu um erro ao enviar o formulário.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (data) => {
    console.log("requisição", data);

    try {
      const response = await http.post(`/euro/api/times`, data); 

      if (response.ok) {
        toast({
          title: "Inclusão bem-sucedida.",
          description: "Time inserido com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        clearFormFields();
      } else {
        console.error("Erro ao enviar o formulário:", response.statusText);
        toast({
          title: "Erro na inclusão.",
          description: "Ocorreu um erro ao enviar o formulário.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      toast({
        title: "Erro na inclusão.",
        description: "Ocorreu um erro ao enviar o formulário.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onCloseDialog = () => setIsAlertDialogOpen(false);

  const handleAlertDialog = () => {
    setIsAlertDialogOpen(true);
  };

  return (
    <Box
      backgroundImage={img2}
      backgroundSize="cover"
      backgroundPosition="center"
      flex="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w={isLargerThan480 ? "40%" : "80%"}
        p={4}
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        bg="whiteAlpha.900"
        position="relative"
      >
        <IconButton
          colorScheme="green"
          aria-label="Voltar"
          icon={<IoIosArrowBack />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={2}
          left={2}
        />
        <Text fontSize="2xl" textAlign="center" mb={4}>
          Painel Times
        </Text>

        <form onSubmit={handleSubmit(handleSubmission)}>
          <FormControl id="nomeTime" isInvalid={errors.nomeTime}>
            <FormLabel>Nome do Time</FormLabel>
            <Controller
              name="nomeTime"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  size="sm"
                  borderColor="grey"
                  type="text"
                  errorBorderColor="red.300"
                  focusBorderColor="green.300"
                  variant="filled"
                />
              )}
            />
            <FormErrorMessage>
              {errors.nomeTime && errors.nomeTime.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={3} id="documento" isInvalid={errors.documento}>
            <FormLabel>Documento</FormLabel>
            <Controller
              name="documento"
              control={control}
              render={({ field }) => (
                <InputMask
                  mask="999.999.999-99"
                  value={field.value}
                  onChange={handleDocumentoChange}
                >
                  {() => (
                    <Input
                      size="sm"
                      borderColor="grey"
                      errorBorderColor="red.300"
                      focusBorderColor="green.300"
                      variant="filled"
                    />
                  )}
                </InputMask>
              )}
            />
            <FormErrorMessage>
              {errors.documento && errors.documento.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={3}>
            <FormLabel>Escudo</FormLabel>
            <input type="file" name="file" onChange={changeHandler} />
            {isFilePicked ? (
              <div>
                <p>{selectedFile.name}</p>
                <p>{selectedFile.size}</p>
              </div>
            ) : (
              <p>Selecione um arquivo</p>
            )}
          </FormControl>

          <Button
            colorScheme="green"
            mt={4}
            type="submit"
            isLoading={isLoading}
          >
            Enviar
          </Button>
        </form>

        <Button colorScheme="green" mt={4} onClick={handleOpenUserModal}>
          Adicionar Usuário
        </Button>
        {isUserModalOpen && (
          <UserModal isOpen={isUserModalOpen} onClose={handleCloseUserModal} />
        )}

        {/* <Button colorScheme="blue" mt={4} onClick={handleAlertDialog}>Inscrever em Campeonatos</Button> */}
        {/* <AlertDialog
          isOpen={isAlertDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseDialog}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Alerta de confirmação
              </AlertDialogHeader>

              <AlertDialogBody>
                Você Deseja Incluir o time na Copa Euro 30+?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseDialog}>
                  Cancelar
                </Button>
                <Button colorScheme="red" onClick={onCloseDialog} ml={3}>
                  Sim
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog> */}
      </Box>
    </Box>
  );
};

export default TeamCreationPage;
