import { useState, useEffect } from "react";
import {
  Table as ChakraUITable,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
  Flex,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { http } from "../services/http";

function ClassificacoesJogo() {
  const [classificacoes, setClassificacoes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    http
      .get("/euro/api/classificacao/1")
      .then((response) => {
        setClassificacoes(response.data.conteudo);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao carregar classificações:", error);
        setLoading(false);
      });
  }, []);

  const getNumeroBgColor = (index) => {
    console.log("index:" , index)
    if (index === 0 || index === 1) {
      return "#FFD700";
    } else if (index >= 2 && index <= 5) {
      return "#FFFF00";
    } else if (index >= 6 && index <= 9) {
      return "#D3D3D3";
    } else {
      return "#EEEEEE";
    }

  };

  return (
    <Flex flexDirection="column" alignItems="center" overflowX="auto" marginX="auto" w="100%">
      <Heading fontSize={["xl", "2xl", "3xl"]} mt={["5%", "3%", "2%"]} mb={["5%", "3%", "2%"]}>
        Classificação Copa Euro 35+
      </Heading>
      <Flex direction="column" w="100%" overflowY="auto" maxH="calc(100vh - 200px)">
        <ChakraUITable >
          <Thead position="sticky" top={0} bg="white" zIndex="sticky">
            <Tr>
              <Th textAlign="center"></Th>
              <Th textAlign="center">Time</Th>
              <Th>Jogos</Th>
              <Th>Pontos</Th>
              <Th>Vitórias</Th>
              <Th>Derrotas</Th>
              <Th>Empates</Th>
              <Th>Gols Pró</Th>
              <Th>Gols Contra</Th>
              <Th>Saldo de Gols</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={9} textAlign="center">
                  Carregando...
                </Td>
              </Tr>
            ) : (
              classificacoes.map((classificacao, index) => (
                <Tr key={index}>
                  <Td
                    textAlign="center"
                    bg={getNumeroBgColor(index)}
                    width="10px"
                    p="1px"
                  >
                    {index + 1}
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar
                        src={`data:image/jpeg;base64,${classificacao.timesResponse.imagem}`}
                        alt="Logo do Time"
                        boxSize={["30px", "40px"]}
                        mr="10px"
                      />
                      <Text>{classificacao.timesResponse.nomeTime}</Text>
                    </Flex>
                  </Td>
                  <Td textAlign="center">{classificacao.qtdJogos}</Td>
                  <Td textAlign="center">{classificacao.pontos}</Td>
                  <Td textAlign="center">{classificacao.vitoria}</Td>
                  <Td textAlign="center">{classificacao.derrota}</Td>
                  <Td textAlign="center">{classificacao.empate}</Td>
                  <Td textAlign="center">{classificacao.golsPro}</Td>
                  <Td textAlign="center">{classificacao.golsContra}</Td>
                  <Td textAlign="center">{classificacao.golsPro - classificacao.golsContra}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraUITable>
      </Flex>
    </Flex>
  );
}

export default ClassificacoesJogo;
