import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import ResponsibleData from "../pages/responsible";
import { Home } from "../pages/mesario";
import { JogadoresTable } from "../Components/table";
import { TabelaSemDados } from "../Components/tabelaSemDados";
import ConsultaPagamento from "../pages/consulta";
import PlayerRegistrationForm from "../pages/playerRegistrationForm";
import ConsultaRegistration from "../pages/consultaRegistration";
import AdmEuro from "../pages/admEuro";
import TeamCreationPage from "../pages/teamCreationPage";
import PlayerCreationPage from "../pages/playerCreationPage";
import ChampsCreationPage from "../pages/champsCreationPage";

import "@mui/material/styles";

import FinanceiroPage from "../pages/financeiro";
import PoliticaPrivacidade from "../pages/politicaPrivacidade";
import Support from "../pages/support";
import Mesa from "../pages/mesa";
import PainelEuro from "../pages/painelEuro";
import ClassificacoesJogo from "../pages/classificacoes";
import TabelaJogos from "../pages/tabelaJogo";
import Artilharia from "../pages/artilharia";
import { MainLayout } from "../layouts/main";
import SorteioPage from "../pages/sorteio";
import Disciplina from "../pages/disciplina";
import PlayerUpdatePage from "../pages/playerUpdatePage";
import TabelaGerada from "../pages/tabelaGerada";

export const PagesRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />} path="/">
          <Route element={<Login />} path="/" />
          <Route element={<ResponsibleData />} path="/responsible/:login" />
          {/* <Route element={<DicePlayer />} path="/form1" /> */}
          <Route element={<TeamCreationPage />} path="/teamCreationPage" />
          <Route element={<ChampsCreationPage />} path="/champsCreationPage" />
          <Route element={<PlayerCreationPage />} path="/playerCreationPage" />
          <Route
            element={<ConsultaRegistration />}
            path="/consultaRegistration"
          />
          <Route
            element={<PlayerRegistrationForm />}
            path="/playerRegistrationForm"
          />
          <Route element={<AdmEuro />} path="/admEuro" />
          <Route element={<Home />} path="/mesario" />
          <Route element={<ConsultaPagamento />} path="/consulta" />
          <Route element={<FinanceiroPage />} path="/financeiro" />
          <Route
            element={<PoliticaPrivacidade />}
            path="/politicaPrivacidade"
          />
          <Route element={<PainelEuro />} path="/painelEuro" />
          <Route element={<ClassificacoesJogo />} path="/classificacoes" />
          <Route element={<TabelaJogos />} path="/tabelaJogo" />
          <Route element={<Artilharia />} path="/artilharia" />
          <Route element={<SorteioPage />} path="/sorteio" />

          <Route element={<Support />} path="/suporte" />
          <Route element={<Mesa />} path="/mesaOnlineCopaEuro" />
          <Route
            element={<JogadoresTable />}
            path="/tabela/:mandanteId/:visitanteId"
          />
          <Route
            element={<TabelaSemDados />}
            path="/tabelasemdados/:mandanteId/:visitanteId"
          />
          <Route element={<TabelaSemDados />} path="/tabelasemdados" />
          <Route element={<Disciplina />} path="/disciplina" />
          <Route element={<PlayerUpdatePage />} path="/playerUpdatePage" />
          <Route element={<TabelaGerada />} path="/tabelaGerada" />

        </Route>
      </Routes>
    </BrowserRouter>
  );
};
