import { useState, useEffect } from "react";
import axios from "axios";
 import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Heading,
  Avatar,
} from "@chakra-ui/react";
import { http } from "../services/http";

function Artilharia() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const golsResponse = await http.get('/euro/api/gols/listar-todos');
        const gols = golsResponse.data.conteudo;

        const atletasResponse = await http.get('/euro/api/atletas/listarTodos');
        const atletas = atletasResponse.data.conteudo;

        const timesResponse = await http.get('/euro/api/times/listarTodos');
        const times = timesResponse.data.conteudo;

        const dadosCombinados = gols.map((gol) => {
          const atleta = atletas.find((atleta) => atleta.id === gol.idAtleta);
          const timeDoAtleta = times.find((time) => atleta && time.idTime === atleta.idTimes);
          return {
            ...gol,
            nomeAtleta: atleta ? formatarNome(atleta.nomeAtleta) : "Atleta não encontrado",
            imagem: timeDoAtleta ? timeDoAtleta.imagem : "",
          };
        });

        const jogadoresComGol = dadosCombinados.filter((jogador) => jogador.quantidade > 0);
        jogadoresComGol.sort((a, b) => b.quantidade - a.quantidade);

        setData(jogadoresComGol);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar artilharia:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getNumeroBgColor = (index) => {
    return "#000"; // Mantenha a lógica de cor como desejado
  };

  const formatarNome = (nome) => {
    return nome.toLowerCase().split(' ').map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1)).join(' ');
  };
  

  return (
    <Flex
      flex="1"
      flexDirection="column"
      alignItems="center"
      overflowX="auto"
      marginX="auto"
      maxHeight="calc(100vh - 14rem)"
      overflowY="auto"
      paddingLeft={2}
    >
      <Heading
        fontSize={["xl", "2xl", "3xl"]}
        mt={["5%", "3%", "2%"]}
        mb={["5%", "3%", "2%"]}
      >
        Artilharia Copa Euro 35+
      </Heading>
      <TableContainer
        overflowX="auto"
        marginX="auto"
        maxHeight="calc(100vh - 14rem)"
        overflowY="auto"
      >
        <ChakraUITable>
          <Thead>
            <Tr>
              <Th textAlign="center"></Th>
              <Th textAlign="center">Atleta</Th>
              <Th textAlign="center">Equipe</Th>
              <Th>Gols</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={4} textAlign="center">
                  Carregando...
                </Td>
              </Tr>
            ) : (
              data.map((item, index) => (
                <Tr key={index}>
                  <Td
                    color={'#fff'}
                    textAlign="center"
                    bgColor={getNumeroBgColor(index)}
                    style={{ padding: "1px" }}
                  >
                    {index + 1}&#176;
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar
                        src={item.imagem}
                        alt="Avatar do Atleta"
                        boxSize={["30px", "40px"]}
                        mr="10px"
                      />
                      <Text>{item.nomeAtleta}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar
                        src={`data:image/jpeg;base64,${item.imagem}`}
                        alt="Logo do Time"
                        boxSize={["35px", "45px"]}
                        mr="10px"
                      />
                      <Text>{item.nomeTime}</Text>
                    </Flex>
                  </Td>
                  <Td textAlign="center">{item.quantidade}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraUITable>
      </TableContainer>{" "}
    </Flex>
  );
}

export default Artilharia;
