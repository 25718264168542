import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  Select,
  Box,
  VStack
} from "@chakra-ui/react";
import { http } from '../services/http';

const PaymentListModal = ({ isOpen, onClose }) => {
  const [payments, setPayments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

 
  const loadTeams = async () => {
    try {
      const response = await http.get('/euro/api/times/listarTodos');
      const teams = response.data.conteudo.map((team) => ({ id: team.idTime, nome: team.nomeTime }));
      setTeams(teams);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const loadPayments = async (teamId) => {
    try {
      const response = await http.get(`/euro/api/pagamento/listarPagamentoTime/${teamId}`);
      const payments = response.data.conteudo;

      for (const payment of payments) {
        const athleteResponse = await http.get(`/euro/api/atletas/${payment.idAtleta}`);
        const athleteDetails = athleteResponse.data.conteudo;
        payment.nomeAtleta = athleteDetails.nomeAtleta;
        payment.documento = athleteDetails.documento;
      }

      setPayments(payments);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  

  useEffect(() => {
    if (isOpen) {
      loadTeams();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedTeam) {
      loadPayments(selectedTeam);
    }
  }, [selectedTeam]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Lista de Pagamentos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select placeholder="Selecione o time" onChange={(e) => setSelectedTeam(e.target.value)}>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.nome}
              </option>
            ))}
          </Select>
          {payments.map((payment) => (
            <Box key={payment.id} borderWidth="1px" borderRadius="lg" overflow="hidden" my={2} p={2}>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm"><strong>Nome:</strong> {payment.nomeAtleta}</Text>
                <Text fontSize="sm"><strong>CPF:</strong> {payment.documento}</Text>
                <Text fontSize="sm"><strong>Data:</strong> {payment.dataPgtoParcela}</Text>
                <Text fontSize="sm"><strong>Valor Pago:</strong> R$ {payment.valParcela},00</Text>
              </VStack>
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentListModal;
