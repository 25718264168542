import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar";
import { Header } from "./components/header";
import { Footer } from "./components/footer";

/**
 * @typedef {Object} MainLayoutProps
 * @property {() => void} onLogout
 */

/**
 *
 * @param {MainLayoutProps} props
 * @returns {JSX.Element}
 */
export function MainLayout({ onLogout }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <Sidebar isOpen={isOpen} onClose={onClose} />
      <Header onOpen={onOpen} />
      <Flex width="full" minHeight="calc(100vh - 112px)">
        <Outlet />
      </Flex>
      <Footer />
    </Box>
  );
}

