import {
  Box,
  Heading,
  Text,
  Link,
  Flex,
  Icon,
  IconButton,
  Grid,
} from "@chakra-ui/react";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import img1 from "../assets/images/a2.jpeg";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function Support() {
  const navigate = useNavigate();

  return (
    <Box
     flex="1"
      bgImage={img1}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
     
    >
      <Flex
         flexDir="column"
        justifyContent="center"
        alignItems="center"
        mt="10%"
      >
        <Box
          bg="white"
          p={4}
          borderWidth={1}
          borderRadius="lg"
          boxShadow="lg"
          mx={4}
        >
          <Grid gridTemplateColumns="1fr 10fr 1fr" mb={8}>
            <IconButton
              colorScheme="green"
              aria-label="Voltar"
              icon={<IoIosArrowBack />}
              onClick={() => navigate(-1)}
            />
            <Heading textAlign="center" as="h1">
              Suporte
            </Heading>
            <Box />
          </Grid>
          <Text fontSize="lg" mb={4}>
            Se você precisar de suporte, pode entrar em contato conosco por
            e-mail ou WhatsApp.
          </Text>
          <Flex align="center" mb={2}>
            <Icon as={FaEnvelope} boxSize={5} mr={2} color="green" />
            <Text>suporte@getsolucoesti.com</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaWhatsapp} boxSize={5} mr={2} color="green" />
            <Link href="https://wa.me/5561992067799">(61) 992067799</Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default Support;
