import { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Input, FormControl, FormLabel, InputGroup, InputRightElement, Box, Image } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { http } from '../services/http';

 

const formatCPF = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};

const AthleteDetailsModal = ({ isOpen, onClose }) => {
  const [cpf, setCpf] = useState("");
  const [athleteData, setAthleteData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async () => {
    setIsSearching(true);
    try { 
       const response = await http.get(`/euro/api/atletas/documento/${cpf}`);
      setAthleteData(response.data.conteudo);
      console.log("athleteData" , response.data.conteudo)
    } catch (error) {
      console.error('There was an error!', error);
      setAthleteData(null);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (cpf) {
      handleSearch();
    }
  }, [cpf]);

  const handleCpfChange = (event) => {
    setCpf(formatCPF(event.target.value));
  };

  const handleClose = () => {
    setCpf('');
    setAthleteData(null);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Dados do Atleta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" alignItems="flex-start">
            <Box flex={1}>
              <FormControl marginBottom={2}>
                <FormLabel htmlFor="cpfSearch">Informe CPF</FormLabel>
                <InputGroup>
                  <Input id="cpfSearch" type="text" value={cpf} onChange={handleCpfChange} borderRadius="md" />
                  <InputRightElement children={<SearchIcon color="gray.500" onClick={handleSearch} />} />
                </InputGroup>
              </FormControl>
              {athleteData && (
                <>
                  <Box borderWidth={1} borderRadius="md" padding={2} marginBottom={2}>
                    <Text fontSize="sm">Nome: {athleteData.nomeAtleta}</Text>
                  </Box>
                  <Box borderWidth={1} borderRadius="md" padding={2} marginBottom={2}>
                    <Text fontSize="sm">Data de Nascimento: {athleteData.dataNasc}</Text>
                  </Box>
                  <Box borderWidth={1} borderRadius="md" padding={2} marginBottom={2}>
                    <Text fontSize="sm">Posição: {athleteData.posicaoAtleta}</Text>
                  </Box>
                  <Box borderWidth={1} borderRadius="md" padding={2} marginBottom={2}>
                    <Text fontSize="sm">Time: {athleteData.nomeTime}</Text>
                  </Box>
                </>
              )}
            </Box>
            {athleteData && (
              <Box marginLeft={4}>
                <Image src={athleteData.imagem} alt="Foto do Atleta" boxSize="6rem" borderRadius="full" />
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AthleteDetailsModal;
