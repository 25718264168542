import afpuma from "../assets/images/afpuma.jpeg";
import ajax from "../assets/images/ajax.jpeg";
import amarelao from "../assets/images/amarelao.jpeg";
import arsenal from "../assets/images/arsenal.jpeg";
import furia from "../assets/images/furia.jpeg";
import galaticos from "../assets/images/galaticos.jpeg";
import arrebite from "../assets/images/arrebite.jpeg";
import mb from "../assets/images/mb.jpeg";
import olympique from "../assets/images/olympique.jpeg";
import sparta from "../assets/images/sparta.jpeg";

import { useState, useEffect } from "react";
import { Image, useMediaQuery } from "@chakra-ui/react";

export const LogosTimes = ({ imageName }) => {
  const [isLargerThan1256] = useMediaQuery("(min-width: 1256px)");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    switch (imageName) {
      case "user.Afpuma":
        setLogo(afpuma);
        break;

      case "user.Ajax":
        setLogo(ajax);
        break;

      case "user.Amarelao":
        setLogo(amarelao);
        break;

      case "user.Arsenal":
        setLogo(arsenal);
        break;

      case "user.Furia":
        setLogo(furia);
        break;

      case "user.Galaticos":
        setLogo(galaticos);
        break;

      case "user.Arrebite":
        setLogo(arrebite);
        break;

      case "user.MeiaBoca":
        setLogo(mb);
        break;

      case "user.Olympique":
        setLogo(olympique);
        break;

      case "user.Sparta":
        setLogo(sparta);
        break;

      default:
        setLogo("");
    }
  }, []);

  return (
    <div>
      <Image w={"2rem"} src={logo}/>
    </div>
  );
};
