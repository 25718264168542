import { Box, Button, Grid, Heading, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { Match } from "../Components/match";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { matches } from "../mocks";

export const Home = () => {
  const [isLargerThan856] = useMediaQuery("(min-width: 856px)");
  const [page, setPage] = useState(1);

  const nextPage = () => setPage((prev) => prev + 1);
  const previousPage = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <Box
      className="h-screen mx-auto max-w-[1280px] text-center"
      textAlign="center"
    >
      <Box display="flex" justifyContent="space-between" p={6}>
        <Button onClick={previousPage} disabled={page <= 1}>
          <ArrowBackIcon />
        </Button>
        <Heading>{page} Rodada</Heading>
        <Button onClick={nextPage}>
          <ArrowForwardIcon />
        </Button>
      </Box>
      <Grid
        templateColumns={
          isLargerThan856
            ? "repeat(auto-fit, minmax(350px, 1fr))"
            : "repeat(auto-fit, minmax(250px, 1fr))"
        }
        padding={isLargerThan856 ? "8rem 4rem" : "4rem 2rem"}
        gap="1rem"
      >
        {matches.map((match) => (
          <Match
            timeMandante={match.timeCasa.nomeTime}
            timeMandanteLogin={match.timeCasa.timesId.login}
            timeVisitante={match.timeVisitante.nomeTime}
            timeVisitanteLogin={match.timeVisitante.timesId.login}
          />
        ))}
      </Grid>
    </Box>
  );
};