export const matches = [
  {
    timeCasa: {
      gols: 0,
      nomeTime: "Sparta",
      timesId: {
        login: "user.Sparta",
      },
    },
    timeVisitante: {
      gols: 0,
      nomeTime: "Ajax",
      timesId: {
        login: "user.Ajax",
      },
    },
    data: "29/04/2023",
  },
  {
    timeCasa: {
      gols: 0,
      nomeTime: "Arrebite",
      timesId: {
        login: "user.Arrebite",
      },
    },
    timeVisitante: {
      gols: 0,
      nomeTime: "Meia Boca",
      timesId: {
        login: "user.MeiaBoca",
      },
    },
    data: "29/04/2023",
  },
  {
    timeCasa: {
      gols: 0,
      nomeTime: "Arsenal",
      timesId: {
        login: "user.Arsenal",
      },
    },
    timeVisitante: {
      gols: 0,
      nomeTime: "Galaticos",
      timesId: {
        login: "user.Galaticos",
      },
    },
    data: "29/04/2023",
  },
  {
    timeCasa: {
      gols: 0,
      nomeTime: "Olympique",
      timesId: {
        login: "user.Olympique",
      },
    },
    timeVisitante: {
      gols: 0,
      nomeTime: "Furia FC",
      timesId: {
        login: "user.Furia",
      },
    },
    data: "29/04/2023",
  },
  {
    timeCasa: {
      gols: 0,
      nomeTime: "Amarelao",
      timesId: {
        login: "user.Amarelao",
      },
    },
    timeVisitante: {
      gols: 0,
      nomeTime: "Puma",
      timesId: {
        login: "user.Afpuma",
      },
    },
    data: "29/04/2023",
  },
];
