import { useState, useEffect } from "react";
import axios from "axios";
import {
  Table as ChakraUITable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Heading,
  Avatar,
  Image,
} from "@chakra-ui/react";
import img1 from "../assets/images/yellow-card.jpg";
import img2 from "../assets/images/red-card.jpg";
import { http } from "../services/http";

function Disciplina() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cartoesResponse, timesResponse, suspensaoResponse] =
          await Promise.all([
            http.get("/euro/api/cartao/listar-cartoes-campeoanto/1"),
            http.get("/euro/api/times/listarTodos"),
            http.get(
              "/euro/api/suspensao-atleta/listar-suspensao-atleta-campeoanto/1"
            ),
          ]);

        const cartoes = cartoesResponse.data.conteudo;
        const times = timesResponse.data.conteudo;
        const suspensos = suspensaoResponse.data.conteudo;

        // Mapear os times para facilitar a busca por ID
        const mapTimes = times.reduce((acc, time) => {
          acc[time.idTime] = `data:image/jpeg;base64,${time.imagem}`;
          return acc;
        }, {});

        // Preparar dados dos atletas com informações de cartões, times e suspensão
        const dadosAtletas = cartoes.map((atleta) => ({
          ...atleta,
          nomeAtleta: formatarNome(atleta.nomeAtleta),
          imagemTime: mapTimes[atleta.idTime],
          dataSuspensao: suspensos.find((s) => s.idAtleta === atleta.idAtleta)
            ?.dataSuspensao,
        }));

        setData(dadosAtletas);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar disciplina:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatarDataLocal = (dataISO) => {
    const data = new Date(dataISO + "T00:00:00");
    return data.toLocaleDateString("pt-BR");
  };

  const getNumeroBgColor = (index) => {
    return "#000"; // Mantenha a lógica de cor como desejado
  };

  const formatarNome = (nome) => {
    return nome
      .toLowerCase()
      .split(" ")
      .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1))
      .join(" ");
  };

  return (
    <Flex
      flex="1"
      flexDirection="column"
      alignItems="center"
      overflowX="auto"
      marginX="auto"
      maxHeight="calc(100vh - 14rem)"
      overflowY="auto"
      paddingLeft={2}
    >
      <Heading
        fontSize={["xl", "2xl", "3xl"]}
        mt={["5%", "3%", "2%"]}
        mb={["5%", "3%", "2%"]}
      >
        Disciplina Copa Euro 35+
      </Heading>
      <TableContainer
        overflowX="auto"
        marginX="auto"
        maxHeight="calc(100vh - 14rem)"
        overflowY="auto"
      >
        <ChakraUITable>
          <Thead>
            <Tr>
              <Th textAlign="center">#</Th>
              <Th textAlign="center">Atleta</Th>
              <Th textAlign="center">Equipe</Th>
              <Th textAlign="center">Cartões</Th>
              <Th textAlign="center">Suspenso até</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={5} textAlign="center">
                  Carregando...
                </Td>
              </Tr>
            ) : (
              data.map((item, index) => (
                <Tr key={index}>
                  <Td
                    color={"#fff"}
                    textAlign="center"
                    bgColor={getNumeroBgColor(index)}
                    style={{ padding: "1px" }}
                  >
                    {index + 1}&#176;
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar
                        src={
                          item.imagem
                            ? `data:image/jpeg;base64,${item.imagem}`
                            : ""
                        }
                        alt="Avatar do Atleta"
                        boxSize={["30px", "40px"]}
                        mr="10px"
                      />
                      <Text>{item.nomeAtleta}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar
                        src={item.imagemTime}
                        alt="Logo do Time"
                        boxSize={["35px", "45px"]}
                        mr="10px"
                      />
                      <Text>{item.nomeTime}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      {Array.from(
                        { length: item.quantidadeParcialAmarelo },
                        (_, index) => (
                          <Image
                            key={`amarelo-${index}`}
                            src={img1}
                            alt="Cartão Amarelo"
                            boxSize="20px"
                            mr="2"
                          />
                        )
                      )}
                      {Array.from(
                        { length: item.quantidadeParcialVermelho },
                        (_, index) => (
                          <Image
                            key={`vermelho-${index}`}
                            src={img2}
                            alt="Cartão Vermelho"
                            boxSize="20px"
                            mr="2"
                          />
                        )
                      )}
                    </Flex>
                  </Td>

                  <Td>
                    {item.dataSuspensao
                      ? formatarDataLocal(item.dataSuspensao)
                      : "Não"}
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraUITable>
      </TableContainer>
    </Flex>
  );
}

export default Disciplina;
