import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, FormErrorMessage, IconButton, Text, Flex, VStack, useDisclosure } from '@chakra-ui/react';
import { useToast } from "@chakra-ui/react"
import axios from 'axios';
import CampeonatoModal from '../Components/campeonatoModal';
import { IoIosArrowBack } from "react-icons/io";
import img2 from "../assets/images/a2.jpeg";
import CreateCompetitionModal from '../Components/createCompetitionModal';
import { http } from '../services/http';

const ChampionshipCreationPage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        nomeCampeonato: Yup.string().required('Nome do Campeonato é obrigatório'),
        categoria: Yup.string().required('Categoria é obrigatória'),
        idadeMinima: Yup.number().typeError('Idade mínima é obrigatória').required(),
        idadeMaxima: Yup.number().typeError('Idade máxima é obrigatória').required(),
      }),
    ),
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: campeonatoModalIsOpen, onOpen: campeonatoModalOnOpen, onClose: campeonatoModalOnClose } = useDisclosure();
  const { isOpen: competitionModalIsOpen, onOpen: competitionModalOnOpen, onClose: competitionModalOnClose } = useDisclosure();
  
  const handleSubmission = async (data) => {
    try {
      const imageBase64 = await toBase64(selectedFile);
      console.log(
        "nomeCampeonato:", data.nomeCampeonato,
        "categoria:", data.categoria,
        "idadeMinima:", data.idadeMinima,
        "idadeMaxima:", data.idadeMaxima,
        "imagem:", imageBase64,
      )
      await http.post('/euro/api/campeonatos', {  
        nomeCampeonato: data.nomeCampeonato,
        categoria: data.categoria,
        idadeMinima: data.idadeMinima,
        idadeMaxima: data.idadeMaxima,
        imagem: imageBase64,
      });

      toast({
        title: "Inclusão bem-sucedida.",
        description: "Campeonato inserido com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate(-1);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };


  const handleImageChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  return (
    <Box bgImage={`url(${img2})`} bgSize='cover' bgPosition='center'  flex="1"  >
      <Box
        w={["90%", "40%"]}
        p={4}
        mt={4}
       
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        mx="auto"
        backgroundColor="white"
        position="relative"
      >
        <IconButton 
         colorScheme="green" 
          icon={<IoIosArrowBack />} 
          isRound='true' 
          position='absolute' 
          top='1rem' 
          left='1rem' 
          onClick={() => navigate(-1)}
        />
        <Text fontSize="xl" textAlign="center" mb={4}>Painel de Campeonatos</Text>

        <form onSubmit={handleSubmit(handleSubmission)}>
          <FormControl id="nomeCampeonato" isInvalid={errors.nomeCampeonato}>
            <FormLabel fontSize={["lg", "md"]}>Nome do Campeonato</FormLabel>
            <Input size={["md", "sm"]} type="text" {...register("nomeCampeonato")} errorBorderColor="red.300" />
            <FormErrorMessage>{errors.nomeCampeonato?.message}</FormErrorMessage>
          </FormControl>

          <FormControl id="categoria" mt={4} isInvalid={errors.categoria}>
            <FormLabel fontSize={["lg", "md"]}>Categoria</FormLabel>
            <Input size={["md", "sm"]} type="text" {...register("categoria")} errorBorderColor="red.300" />
            <FormErrorMessage>{errors.categoria?.message}</FormErrorMessage>
          </FormControl>

          <Flex mt={4}>
            <FormControl id="idadeMinima" flex="1" isInvalid={errors.idadeMinima}>
              <FormLabel fontSize={["lg", "md"]}>Idade Mínima</FormLabel>
              <Input size={["md", "sm"]} type="number" {...register("idadeMinima")} errorBorderColor="red.300" />
              <FormErrorMessage>{errors.idadeMinima?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id="idadeMaxima" flex="1" ml={4} isInvalid={errors.idadeMaxima}>
              <FormLabel fontSize={["lg", "md"]}>Idade Máxima</FormLabel>
              <Input size={["md", "sm"]} type="number" {...register("idadeMaxima")} errorBorderColor="red.300" />
              <FormErrorMessage>{errors.idadeMaxima?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <FormControl id="file" mt={4}>
            <FormLabel fontSize={["lg", "md"]}>Upload de Imagem</FormLabel>
            <Input size={["md", "sm"]} type="file" onChange={handleImageChange} errorBorderColor="red.300" />
          </FormControl>

          {isFilePicked ? (
            <div>
              <p>Filename: {selectedFile ? selectedFile.name : ''}</p>
              <p>Filetype: {selectedFile ? selectedFile.type : ''}</p>
              <p>Size in bytes: {selectedFile ? selectedFile.size : ''}</p>
            </div>
          ) : (
            <p>Select a file to show details</p>
          )}

          <VStack spacing={4} mt={4} align="stretch">
            <Button colorScheme="green" type="submit">Enviar</Button>
            <Button colorScheme="green" onClick={campeonatoModalOnOpen}>Listar Campeonatos</Button>
            <CampeonatoModal isOpen={campeonatoModalIsOpen} onClose={campeonatoModalOnClose} />
            <Button colorScheme="green" onClick={competitionModalOnOpen}>Criar Competição</Button>
            <CreateCompetitionModal isOpen={competitionModalIsOpen} onClose={competitionModalOnClose} />
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

async function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default ChampionshipCreationPage;
