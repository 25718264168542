import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from "@chakra-ui/react";
import html2pdf from "html2pdf.js";
import { http } from "../services/http";

const TeamAthletesModal = ({ isOpen, onClose, idCampeonato, idTimes }) => {
  const [athletes, setAthletes] = useState([]);
  const [generateForPDF, setGenerateForPDF] = useState(false);

  function formatDateString(dateStr) {
    const [day, month, year] = dateStr
      .split("/")
      .map((part) => part.padStart(2, "0"));
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const fetchAthleteData = async () => {
      try {
         const responseSumula = await http.get(`/euro/api/sumula/${idCampeonato}/${idTimes}`);
        const athleteData = responseSumula.data.conteudo.map(item => ({
          id: item.sumulaId.idAtletas,
          apto: item.aptoJogo,
           parcela: item.parcela,
          data: item.data,
          totalPago: item.totalPago
        }));

        const updatedAthletes = await Promise.all(
          athleteData.map(async athlete => {
            const response = await http.get(`/euro/api/atletas/${athlete.id}`);
            athlete.nome = response.data.conteudo.nomeAtleta;

            const paymentResponse = await http.get(`/euro/api/pagamento/listar-pgto-atleta/1/${athlete.id}`);
            
            if (paymentResponse.data && paymentResponse.data.conteudo && paymentResponse.data.conteudo.length > 0) {
              const paymentData = paymentResponse.data.conteudo[paymentResponse.data.conteudo.length - 1];

              athlete.totalPago = paymentData.valParcela;
              if (paymentData.dataPgtoParcela) {
                athlete.data = formatDateString(paymentData.dataPgtoParcela);
              }
              athlete.parcela = paymentData.numParcela;
            }

            return athlete; 
          })  
        );

        setAthletes(updatedAthletes);
      } catch (error) {
        console.error('There was an error!', error.message);
        if (error.response) {
            console.log('Server response:', error.response.data);
        }
      }
    };

    fetchAthleteData();
  }, [idCampeonato, idTimes]);

  const generatePDF = () => {
    setGenerateForPDF(true);
    setTimeout(() => {
      const element = document.getElementById("athletes-table");
      const opt = {
        margin: 10,
        padding: 30,
        filename: 'Athletes.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, windowWidth: document.body.scrollWidth, windowHeight: document.body.scrollHeight },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().from(element).set(opt).save().then(() => {
        setGenerateForPDF(false);
      });
    }, 50);
  };
  function formatCurrency(value) {
    // Se o valor é indefinido ou nulo, retornamos "R$0,00"
    if (value === null || value === undefined) return "R$0,00";

    // Tentando converter a string para um número
    const numberValue = parseFloat(value);

    // Se o valor não é um número ou é NaN, retornamos "R$0,00"
    if (isNaN(numberValue)) return "R$0,00";

    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);

    return formattedValue;
  }

  return (
    <>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Lista de Atletas</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Button
                onClick={generatePDF}
                colorScheme="blue"
                marginBottom="1rem"
              >
                Gerar PDF
              </Button>
              <Table variant="simple" id="athletes-table">
                {generateForPDF && (
                  <caption
                    style={{
                      textAlign: "center",
                      color: "green",
                      marginBottom: "20px",
                      fontSize: "1.3em",
                    }}
                  >
                    Copa Euro 30+
                  </caption>
                )}
                <Thead>
                  <Tr>
                    <Th
                      width="40%"
                      border="1px solid black"
                      textAlign="center"
                      padding={generateForPDF ? "2px 4px" : "8px 12px"}
                    >
                      Nome do Atleta
                    </Th>
                    <Th
                      width="10%"
                      border="1px solid black"
                      textAlign="center"
                      padding={generateForPDF ? "2px 4px" : "8px 12px"}
                    >
                      Apto
                    </Th>
                    {generateForPDF && (
                      <>
                        <Th
                          width="5%"
                          border="1px solid black"
                          textAlign="center"
                          fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                          height={generateForPDF ? "20px" : "auto"}
                        >
                          Pgto
                        </Th>
                        <Th
                          width="25%"
                          border="1px solid black"
                          textAlign="center"
                          fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                          height={generateForPDF ? "20px" : "auto"}
                        >
                          Data
                        </Th>
                        <Th
                          width="20%"
                          border="1px solid black"
                          textAlign="center"
                          fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                          height={generateForPDF ? "20px" : "auto"}
                        >
                          Total Pago
                        </Th>
                      </>
                    )}
                  </Tr>
                </Thead>

                <Tbody>
                  {athletes.map((athlete, index) => (
                    <Tr key={index}>
                      <Td
                        border="1px solid black"
                        textAlign="center"
                        fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                        height={generateForPDF ? "20px" : "auto"}
                      >
                        {athlete.nome}
                      </Td>
                      <Td
                        border="1px solid black"
                        textAlign="center"
                        fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                        height={generateForPDF ? "20px" : "auto"}
                      >
                        {athlete.apto}
                      </Td>
                      {generateForPDF && (
                        <>
                          <Td
                            border="1px solid black"
                            textAlign="center"
                            fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                            height={generateForPDF ? "20px" : "auto"}
                          >
                            {athlete.parcela}
                          </Td>
                          <Td
                            border="1px solid black"
                            textAlign="center"
                            fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                            height={generateForPDF ? "20px" : "auto"}
                          >
                            {athlete.data}
                          </Td>
                          <Td
                            border="1px solid black"
                            textAlign="center"
                            fontSize={generateForPDF ? "10px" : "initial"} // Adicione ou ajuste esta linha
                            height={generateForPDF ? "20px" : "auto"}
                          >
                            {athlete.totalPago
                              ? `R$${parseFloat(athlete.totalPago)
                                  .toFixed(2)
                                  .replace(".", ",")}`
                              : "R$0,00"}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TeamAthletesModal;
