import { Flex, IconButton, Image } from "@chakra-ui/react";
import { RiMenuLine, RiSettings3Line } from "react-icons/ri";
import img1 from "../.././../assets/images/euro.svg";

/**
 * @typedef {Object} IProps
 * @property {() => void} onOpen
 */

/**
 *
 * @param {IProps} props
 * @returns {JSX.Element}
 */
export function Header({ onOpen }) {
  return (
    <Flex
      boxShadow="lg"
      width="full"
      height="14"
      paddingInline="5"
      backgroundColor="#000000"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton aria-label="menu-ib" variant="unstyled" onClick={onOpen}>
        <Flex justifyContent="center">
          <RiMenuLine size={24} fill="white" />
        </Flex>
      </IconButton>
      <Image src={img1} alt="Logo" maxW="250px"  mb={3} />

      <IconButton aria-label="menu-ib" variant="unstyled"></IconButton>
    </Flex>
  );
}
