import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

const CreateCompetitionModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent> 
        <ModalHeader>Criar Competição</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Coloque aqui o conteúdo que deseja exibir na modal de criação de competição */}
          {/* Por exemplo, pode colocar um formulário para criar a competição aqui */}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={onClose}>
            Fechar
          </Button>
          {/* Aqui você pode colocar um botão para salvar os dados do formulário, por exemplo */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateCompetitionModal;
